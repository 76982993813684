import React, { useState, useEffect } from 'react';
import SearchBar from '../components/SearchBar';
import btn_close from '.././assets/img/inbox/btn_close.svg';
import { Button } from '@mui/material';

export default function TemplateMessageList(props) {
  const inputLimit = 200;
  const { templateMessages, onClose, context, currentContact, currentChannel, getMessages } = props;
  const { socket } = context;
  const [selectedTab, setSelectedTab] = useState(0);
  const [inputMsg, setInputMsg] = useState({});
  const [error, setError] = useState({});
  const [messageList, setMessageList] = useState(templateMessages);
  const [search, setSearch] = useState('');

  const onSearchChange = (value) => {
    setSelectedTab(null);
    var list = templateMessages;
    if (value.trim().length > 0) {
      list = list.filter(x => x.name.toUpperCase().includes(value.toUpperCase()));
    } else {
      list = templateMessages;
    }
    setMessageList(list);
    setSearch(value);
  }

  const handleInputChange = (index, e) => {
    if(e.target.value.length > inputLimit) return;
    setInputMsg({...inputMsg, [index.toString()]: e.target.value});
    checkError(index, e.target.value);
  }

  const handleSelectTab = (index) => {
    setSelectedTab(index);
    reConfig(index);
  }

  const reConfig = (index = 0) => {
    setError({});
    let tempError = {};
    messageList[index].content.match(/{{(\d+)}}/g)?.map((item, index) => {
      tempError = {...tempError, [index]: 'limit' };
    });
    setError(tempError);
    setInputMsg({});
  }

  const isError = () => {
    return (Object.keys(error).length) ? true : false;
  }

  const checkError = (index, value) => {
    if (value.length) {
      delete error[index]
      setError(error);
    } else {
      setError({...error, [index]: 'limit'});
    }
  }

  const sendExpiredMessage = () => {
    if (isError()) return;
    let paramData = [];
    Object.entries(inputMsg).forEach(([k, v]) => paramData.push({type: 'text', text: inputMsg[k]}));
    var data = {
      to: currentContact.whatsappId,
      channelPhoneNumber: currentChannel.channelPhoneNumber,
      namespace: messageList[selectedTab].namespace,
      elementName: messageList[selectedTab].elementName,
      language: { "policy": "deterministic", "code": messageList[selectedTab].languageCode },
      components: [{ type: 'body', parameters: paramData }]
    }

    onClose();
    socket.emit('sendTemplateMessageViaWS', data, (err, res) => {
      if (err) {
        alert(err);
      } else {
        //reload get message
        getMessages();
      }
    });
  }

  const renderTab = () => {
    const listItems = messageList?.map((item, index) =>
      <span key={`expiredTemplateMessageListTab_${index.toString()}`} onClick={() => handleSelectTab(index)} className={selectedTab == index ? 'active' : ''}>{item.name}</span>
    )
    return (
      <>
        <div className='tab-container'>
          <div className="title">範本種類</div>
          <SearchBar 
          keyword={search}
          onChange={(value) => onSearchChange(value)} customStyle={{ flex: 0 }} />
          {listItems}
        </div>
      </>
    );
  }

  const renderInput = (content) => {
    const inputItems = content.match(/{{(\d+)}}/g)?.map((item, index) =>
      <>
      <label className='input-label' htmlFor={`expiredMsgInput_${index}`}>{inputMsg[index]?.length??0} / {inputLimit}</label>
      <input key={`expiredMsgInput_${index}`} id={`expiredMsgInput_${index}`} type="text" value={`${inputMsg[index]??''}`} placeholder={`請填上${item}的內容`} onChange={event => handleInputChange(index, event)} />
      </>
    )
    return inputItems ? (
      <>
        <div className='title'>範本訊息<span className='sub-title'>所有空格框必需填寫內容才能送出</span></div>
        <div className='input-container'>
          {inputItems}
        </div>
      </>
    ) : '';
  }

  const renderList = (messages) => {
    return (
      <>
        <div className='title-close-container'>
          <div className='title'>範本預覽</div>
          <div><img src={btn_close} style={{width:15,height:15,textAlign:'right'}} onClick={onClose} /></div>
        </div>
        <div className='message'>{messages.content}</div>
        {renderInput(messages.content)}
        <div className='button-container'>
          <Button variant="contained" className='send' onClick={() => { sendExpiredMessage(); }} disabled={isError()}>傳送</Button>
        </div>
      </>
    );
  }

  useEffect(() => {
    reConfig();
  }, [])

  return (
    <>
      <div id="expired-template-message">
        <div className='expired-message-container'>
          {renderTab()}
          <div className='content-container'>
            {selectedTab != null && renderList(messageList[selectedTab])}
          </div>
        </div>
      </div>
    </>
  )
}