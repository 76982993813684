import React from 'react';
import PropTypes from 'prop-types';
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import ic_attachment from '.././assets/img/inbox/ic_attachment.svg';
import ic_emoji from '.././assets/img/inbox/ic_emoji.svg';
import ic_quick_answer from '.././assets/img/inbox/ic_quick_answer.svg';
import ic_emoji_w from '.././assets/img/inbox/ic_emoji_w.svg';
import ic_quick_answer_w from '.././assets/img/inbox/ic_quick_answer_w.svg';
import { TemplateMessageList, ExpiredTemplateMessageList } from '../components';
import { connect } from 'react-redux';

const CryptoJS = require("crypto-js");
const textareaLineHeight = 24;
class MessageBox extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      text: '',
      showEmojiPicker: false,
      showDefaultMessage: false,
      rows: 3,
      minRows: 3,
      maxRows: 5,
      showTemplateMessages: false,
      selectedTemplateMessage: null,
      sendingMsg: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.currentChannel !== nextProps.currentChannel) {
      this.setState({ text: '', showTemplateMessages: false });
    }
  }

  sendMessage() {
    const { isExpired } = this.props;
    this.setState({ showEmojiPicker: false, showDefaultMessage: false, sendingMsg: true });
    if (isExpired == false) {
      if (!this.state.text || this.state.text.length === 0 || !this.state.text.trim()) return;

      const { socket } = this.context;
      const { currentChannel } = this.props;
      var data = {
        toContactId: currentChannel.id,
        type: 'text',
        content: { body: this.state.text },
      }
      socket.emit('sendMessage', data, (err, res) => {
        if (err) {
          alert(err);
        }
        this.setState({ text: '', rows: this.state.minRows, sendingMsg: false })
      });
      // console.log('sendMessage: ' + this.state.text + ', id: ' + currentChannel.id)
    } else {
      alert('對話已超時!');
    }
  }

  sendTemplateMessage(message) {
    const { socket } = this.context;
    const { currentContact, currentChannel } = this.props;
    var data = {
      to: currentContact.whatsappId,
      channelPhoneNumber: currentChannel.channelPhoneNumber,
      namespace: message.namespace,
      elementName: message.elementName,
      language: { "policy": "deterministic", "code": message.languageCode },
      components: [{ type: 'body', parameters: [] }]
    }

    this.setState({ showTemplateMessages: false, selectedTemplateMessage: null })
    socket.emit('sendTemplateMessageViaWS', data, (err, res) => {
      if (err) {
        alert(err);
      }
    });
  }

  onUploadFile(e) {
    const { socket } = this.context;
    const media = e.target.files[0];
    console.log(media);

    if (this.props.isExpired == false) {
      if (media) {
        var fileReader = new FileReader();
        this.props.onUploadMedia(media);
        fileReader.onload = async (event) => {
          // console.log(CryptoJS.SHA256(CryptoJS.enc.Latin1.parse(event.target.result)).toString());
          socket.emit('mediaUpload/initiate', {
            fileName: media.name,
            mimeType: media.type,
            fileSize: media.size,
            checksum: CryptoJS.SHA256(CryptoJS.enc.Latin1.parse(event.target.result)).toString(),
          }, (error, data) => {
            // console.log(error, data);
            if (error) alert(error);
            else {
              // this.props.onUploadMedia(data.mediaId, media);
              console.log('mediaId: ' + data.mediaId);
            }
          });
        };
        fileReader.readAsBinaryString(media);
      }
    } else {
      alert('對話已超時!');
    }
  }

  resumeUploadFile() {
    const { socket } = this.context;

    socket.emit('mediaUpload/resume', {
      mediaId: 20,
    }, (error, data) => {
      console.log(error, data);
    });
  }

  handleChange = (event) => {
    const { minRows, maxRows, sendingMsg } = this.state;

    if (sendingMsg == true) return;
    const previousRows = event.rows;
    event.rows = minRows;
    const currentRows = ~~(event.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.rows = maxRows;
      event.scrollTop = event.scrollHeight;
    }

    this.setState({
      text: event.value,
      rows: currentRows < maxRows ? currentRows : maxRows,
    });
  }

  showEmojiPicker() {
    this.setState(prevState => ({
      showEmojiPicker: !prevState.showEmojiPicker,
      showDefaultMessage: false,
    }))
  }

  showDefaultMessage() {
    this.setState(prevState => ({
      showDefaultMessage: !prevState.showDefaultMessage,
      showEmojiPicker: false,
    }))
  }

  addEmoji = (e) => {
    // console.log(e)
    let sym = e.unified.split('-')
    let codesArray = []
    sym.forEach(el => codesArray.push('0x' + el))
    let emojiPic = String.fromCodePoint(...codesArray)
    this.setState({
      text: this.state.text + emojiPic
    })
  }

  onSelectMessage(msg) {
    this.setState({ text: msg }, () => { this.handleChange(this.textarea) });
  }

  render() {
    const { currentChannel, currentContact, isExpired, channels, getMessages } = this.props;
    const { showEmojiPicker, showDefaultMessage, showTemplateMessages, selectedTemplateMessage, sendingMsg } = this.state;
    const templateMessages = channels?.find(x => x.channelPhoneNumber == currentChannel.channelPhoneNumber)?.templateMessages || [];
    const disableSendButton = sendingMsg == true || this.state.text.length == 0;

    if (isExpired === false) {
      return (
        <div id="message-box">
          <textarea
            type="text"
            rows={this.state.rows}
            value={this.state.text}
            onChange={(event) => { this.handleChange(event.target) }}
            placeholder="輸入解答"
            ref={(textarea) => { this.textarea = textarea }}
          />
          <div className='tool-bar'>
            <img className={`icon ${showDefaultMessage && 'selected'}`} src={showDefaultMessage ? ic_quick_answer_w : ic_quick_answer} onClick={() => { this.showDefaultMessage() }} />
            <div style={{ flex: 1, display: 'inline-flex', justifyContent: 'flex-end' }}>
              <img className={`icon ${showEmojiPicker && 'selected'}`} src={showEmojiPicker ? ic_emoji_w : ic_emoji} onClick={() => { this.showEmojiPicker() }} />
              <label style={{ display: 'inline-flex' }}><input type="file" name="uploadImage" accept="*" onChange={(e) => { this.onUploadFile(e) }} /><img src={ic_attachment} className='icon' /></label>
              <button className={`send-button ${disableSendButton && 'diabled'}`} onClick={() => { this.sendMessage() }} disabled={disableSendButton}>傳送</button>
            </div>
          </div>
          <Picker theme='light' set='apple' onSelect={this.addEmoji} style={showEmojiPicker == false ? styles.hide : {}} />
          {showDefaultMessage && <TemplateMessageList onClick={(msg) => { this.onSelectMessage(msg) }} currentChannel={currentChannel} />}
          {/* <div className={this.state.showEmojiPicker == false ? 'emoji-picker-hide' : ''}><Picker onSelect={this.addEmoji} /></div> */}
        </div>
      )
    } else {
      return (
        <div id="expiredMessage-box">
          {!showTemplateMessages ?
            <div className="expired-text">已結束通訊，無法輸入。{templateMessages?.length > 0 && <span className="send-template-msg" onClick={() => this.setState({ showTemplateMessages: true })}>發送範本訊息</span>}</div>
            :
              <ExpiredTemplateMessageList
              onClose={() => this.setState({ showTemplateMessages: false, selectedTemplateMessage: null })} 
              currentChannel={currentChannel}
              currentContact={currentContact}
              templateMessages={templateMessages}
              context={this.context}
              getMessages={getMessages}
              />
          }
        </div>
      )
    }
  }
}

MessageBox.contextTypes = {
  socket: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return { channels: state.reducer.channels }
}

export default connect(mapStateToProps)(MessageBox);

const styles = {
  hide: {
    visibility: 'hidden',
  },
}