import { combineReducers } from 'redux'
import {
  UPDATE_CHANNELS,
  UPDATE_AUTH,
} from './action';

const initialState = {
  isAuthenticated: null,
  channels: []
}

function reducer(state = initialState, action) {

  switch (action.type) {
    case UPDATE_CHANNELS: {
      return {
        ...state,
        channels: action?.channels,
      }
    }
    case UPDATE_AUTH: {
      return {
        ...state,
        isAuthenticated: action?.isAuthenticated,
      }
    }

    default:
      return state
  }
}

const rootReducer = combineReducers({
  reducer
})

export default rootReducer