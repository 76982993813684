import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import Login from "./views/login/Login";
import MenuProvider from "./components/menu/MenuProvider";
import UserMenu from "./components/menu/UserMenu";
import ChatRoom from "./views/chat/ChatRoom";
import PermissionSetting from './views/setting/PermissionSetting';
import ChannelStatus from './views/channelStatus/ChannelStatus';

import ic_inbox_0 from './assets/img/menu/ic_inbox_0.svg';
import ic_inbox_1 from './assets/img/menu/ic_inbox_1.svg';
import ic_right_0 from './assets/img/menu/ic_right_0.svg';
import ic_right_1 from './assets/img/menu/ic_right_1.svg';
import ic_channel_0 from './assets/img/channel_status/ic_channel_0.svg';
import ic_channel_1 from './assets/img/channel_status/ic_channel_1.svg';
import illust_nomsg from './assets/img/illust/illust_nomsg.svg';
import { connect } from 'react-redux';

import { Auth } from './Auth';

function Home() {
  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <img src={illust_nomsg} style={{ width: '50%', height: '50%', resize: 'contain' }} />
      <div style={{ color: '#2c2c2c', marginTop: '24px' }}>Whatsapp tool</div>
    </div>
  )
}

export const routes = [
  {
    path: '/',
    exact: true,
    component: Home
  },
  {
    path: "/inbox",
    menu: {
      text: '訊息解答',
      icon: ic_inbox_0,
      active_icon: ic_inbox_1
    },
    component: ChatRoom
  },
  {
    path: "/permission",
    menu: {
      text: '權限設定',
      icon: ic_right_0,
      active_icon: ic_right_1
    },
    adminRight: true,
    component: PermissionSetting
  },
  {
    path: "/channelStatus",
    menu: {
      text: '頻道狀態',
      icon: ic_channel_0,
      active_icon: ic_channel_1
    },
    component: ChannelStatus
  }
];

class PrivateRoute extends React.Component {
  render() {
    const { component: Component, isAuthenticated, ...rest } = this.props
    // console.log('Auth: ' + Auth.isLoggedIn())
    // console.log('Socket Authenticated: ' + isAuthenticated)
    return (
      <Route
        {...rest}
        render={(props) => Auth.isLoggedIn() === true
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
      />
    )
  }
}

class RouterM extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props?.isAuthenticated !== nextProps?.isAuthenticated && nextProps?.isAuthenticated === false) {
      localStorage.clear();
      this.forceUpdate()
    }
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/login" component={Login} />
          <Route>
            <MenuProvider>
              <UserMenu />
              <Switch>
                {
                  routes && routes.map((route, i) => (
                    <PrivateRoute key={i} component={route.component} {...route} {...this.props} />
                  ))
                }
              </Switch>
            </MenuProvider>
          </Route>
        </Switch>
      </Router>
    )
  }
}

const mapStateToProps = (state) => {
  return { isAuthenticated: state.reducer.isAuthenticated }
}

export default connect(mapStateToProps)(RouterM);