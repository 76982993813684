import React from "react";
import { makeStyles } from '@mui/styles';
import { InputBase } from '@mui/material';
import ic_search from "../assets/img/setting/ic_search.svg";
import { KeyboardInputKey } from "../common/enum/enums";

const useStyles = makeStyles({
  search: {
    display: "flex",
    flexDirection: "row",
    margin: "11px 8px",
    padding: "0px 12px",
    alignItems: "center",
    borderRadius: "18px",
    border: "solid 1px #dedede",
    backgroundColor: "#f6f6f6",
    flex: "1 1 0%",
  },
  input: {
    fontSize: "14px",
    color: "#2c2c2c",
    marginLeft: "6px",
    width: "100%",
  },
});

export default function SearchBar(props) {
  const {
    customStyle = {},
    onChange = () => {},
    keyword = "",
    onKeyDown = () => {},
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.search} style={customStyle}>
      <img src={ic_search} />
      <InputBase
        placeholder="以電話起始數字搜尋"
        value={keyword}
        className={classes.input}
        onChange={(event) => onChange(event.target.value)}
        onKeyDown={(event) => {
          if (event.key == KeyboardInputKey.Enter) {
            onKeyDown(event.target.value);
          }
        }}
      />
    </div>
  );
}
