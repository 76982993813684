import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { Auth } from '../../Auth';
import Cookies from 'universal-cookie';
import { AppNotification } from '../../Notification';
import { ChatList, MessageBox, MessageInfo, TextMessage, MediaMessage, LocationMessage, ContactMessage, CallLogMessage } from '../../components';
import illust_nomsg from '../../assets/img/illust/illust_nomsg.svg';
import illust_autoreply from '../../assets/img/illust/illust_autoreply.svg';
import ic_mark from '../../assets/img/inbox/ic_mark.svg';
import { Popover, Tabs, Tab, Tooltip, Badge, Backdrop, CircularProgress } from '@mui/material';
import ClientInfo from '../../components/ClientInfo';
import TopBar from '../../components/TopBar';
import { connect } from 'react-redux';
import * as Regex from '../../util/regex';
import FollowUserEdit from '../../components/FollowUserEdit';

let loadMore = false;
let scrollHeight = 0;
const cookies = new Cookies();

class ChatRoom extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      id: null,
      currentContact: null,
      currentChannel: null,
      contacts: null,
      messages: null,
      hasMoreItems: true,
      loading: false,
      loadingContacts: false,
      uploadMedia: null,
      uploadingMedia: [],
      logoutAnchorEl: null,
      chatListTabIndex: 0,
      clientInfo: null,
      staffList: [],
      popupFollowUserEdit: false,
      checkingMessage: false,
      isSearching: false,
      channelList: [],
      backdropOpen: false,
      filterFollowUserId: 'all',
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.checkingMessage) this.scrollToBottom();
    else if (loadMore) {
      this.scrollParentRef.scrollTop = this.scrollParentRef.scrollHeight - scrollHeight;
      loadMore = false;
    }
  }

  componentDidMount() {
    this.getContacts(this.state.chatListTabIndex);
    this.subscribeContacts();
    this.getAppSetting();
    this.mediaUploadEvent();
    this.getAllUser();
    this.getAllChannel();
  }

  componentWillUnmount() {
    const { socket } = this.context;
    if (this.state.currentChannel) {
      socket.unsubscribe('message/' + this.state.currentChannel.id);
    }

    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  getAllUser() {
    const { socket } = this.context;

    socket.emit("getAllUsers", null, (err, res) => {
      if (err) {
        alert(err);
      } else {
        this.setState({ staffList: res?.filter(e => e.disabled === false) });
      }
    });
  }

  getAllChannel() {
    const { socket } = this.context;
    socket.emit("getChannelStatus", null, (err, res) => {
      if (err) {
        alert(err);
      } else {
        this.setState({ channelList: res });
      }
    });
  }

  resetInfiniteScroll() {
    loadMore = false;
    scrollHeight = 0;
    this.setState({ hasMoreItems: true, checkingMessage: false });
  }

  handleScroll(event) {
    loadMore = false;
    if (event.currentTarget.scrollTop == 0 && this.state.hasMoreItems) {
      // console.log('load More')
      scrollHeight = event.currentTarget.scrollHeight;
      loadMore = true;
      this.loadMore();
    }
    if (!this.state.checkingMessage) this.setState({ checkingMessage: true });
  }

  getAppSetting() {
    const { socket, setState } = this.context;
    socket.emit('getAppSettings', null, (err, res) => {
      if (err) {
        alert(err)
      } else {
        // console.log(res);
        if(res != null && res.length > 0) {
          var maskLength = 0;
          var highlightedContacts = [];
          res.map((item) => {
            if(item?.key == 'defaultPhoneNumberMaskLength') {
              maskLength = item?.value;
            } else if (item?.key === 'highlightedContacts') {
              highlightedContacts = JSON.parse(item.value);
            }
          });
          setState({ defaultPhoneNumberMaskLength: maskLength, highlightedContacts: highlightedContacts });
        }
      }
    });
  }

  getContacts(index, nextFilterFollowUserId = null) {
    const { socket } = this.context;
    this.updateBackdrop(true);
    socket.emit('getContacts', {
      expired: index == 2 ? '1' : '0',
      limit: process.env.REACT_APP_CONTACT_GET_LIMIT,
      followUserId: nextFilterFollowUserId ? nextFilterFollowUserId !== 'all' ? nextFilterFollowUserId : null : this.state.filterFollowUserId !== 'all' ? this.state.filterFollowUserId : null,
    }, (err, res) => {
      if (err) {
        this.updateBackdrop(false);
        alert(err)
      } else {
        this.setState({ chatListTabIndex: index, currentContact: null, contacts: res, loadingContacts: false, backdropOpen: false }) 
      }
    });
  }

  searchChatList({ keywords, isExpired }) {
    const { socket } = this.context;
    this.setState({ loadingContacts: true }) 
    this.updateIsSearching(keywords.length>0);
    socket.emit(
      "getContacts",
      {
        keywords: keywords,
        expired: isExpired,
        limit: process.env.REACT_APP_CONTACT_GET_LIMIT,
        followUserId: this.state.filterFollowUserId !== 'all' ? this.state.filterFollowUserId : null,
      },
      (err, res) => {
        if (err) {
          alert(err);
        } else {
          this.setState({ currentContact: null, contacts: res, loadingContacts: false }) 
        }
      }
    );
  }

  subscribeContacts() {
    const { socket } = this.context;

    var chatChannel = socket.subscribe('contact')
    chatChannel.on('subscribe', (err, res) => {
      if (res) {
        chatChannel.watch((data) => {
          this.setState(prevState => {
            if (!prevState.contacts) prevState.contacts = [];

            // if (this.state.chatListTabIndex == 2) return;
            // console.log('Subscribe contact: ');
            // console.log(data);
            //var contactIndex = prevState.contacts?.findIndex(x => x.whatsappId == data.whatsappId);
            var contactIndex = prevState.contacts?.findIndex(x => (x.whatsappId == data.whatsappId && x.channelPhoneNumber === data.channelPhoneNumber));
            if (data?.messages?.[0]?.lastMessage?.fromWhatsappId != null && data?.messages?.[0]?.unreadCount > 0) {
              //Message from selected brand, don't push notification
              if (data?.messages?.[0]?.id == this.state.currentChannel?.id) {
                return;
              }
              this.props.pushNotification(data?.messages?.[0]);
            }
            if (contactIndex == -1) {
              //add new contact
              const newContactIndex = data.messages && data.messages[0] && data.messages[0].lastMessageAt ? prevState.contacts.findIndex(v => v.messages && v.messages[0] && v.messages[0].lastMessageAt <= data.messages[0].lastMessageAt) : -1;
              if (newContactIndex === -1) {
                prevState.contacts.push(data);
              } else {
                prevState.contacts.splice(newContactIndex, 0, data);
              }
            } else {
              //update contact
              data.messages.map((item,index)=>{
                //if expired, lastMessage is null || lastMessage not null && lastMessageAt > 1 day
                //if not expired, lastMessage is not null and lastMessageAt < 1 day
                if (this.state.currentChannel?.id === item.id) {
                  this.setState(prevState => ({ currentChannel: item }))
                }
                // const validData = (
                //   (this.state.chatListTabIndex === 2 && ( (item.lastMessage && item.lastMessageAt && ( (new Date().getTime() - item.lastMessageAt) > (24 * 3600 * 1000) ) ) || !item.lastMessage)) ||
                //   (this.state.chatListTabIndex !== 2 && item.lastMessage && item.lastMessageAt && ( (new Date().getTime() - item.lastMessageAt) <= (24 * 3600 * 1000) ) )
                // );
                const validData = true;
                if (validData) {
                  var messageIndex = prevState.contacts[contactIndex]?.messages?.findIndex(x => x.id == item?.id);
                  if (messageIndex && messageIndex == -1) {
                    //add new channel messages
                    prevState.contacts[contactIndex].messages.push(item)
                  } else {
                    prevState.contacts[contactIndex].messages[messageIndex] = item;
                  }
                }
              });
            }

            // Sort contact prevState.contacts by the last message time replied by client
            prevState.contacts[contactIndex]?.messages?.sort(function (a, b) {
              return b.lastClientMessageAt - a.lastClientMessageAt;
            });
            prevState.contacts.sort(function (a, b) {
              if (!a.messages[0]?.lastClientMessageAt) {
                return 1;
              }
              if (!b.messages[0]?.lastClientMessageAt) {
                return -1;
              }
              if (a.messages[0]?.lastClientMessageAt === b.messages[0]?.lastClientMessageAt) {
                return 0;
              }
              return b.messages[0]?.lastClientMessageAt - a.messages[0]?.lastClientMessageAt;
            })
            //var isAnswering = this.state.currentContact?.whatsappId == data?.whatsappId && data.messages[0]?.lastMessage?.fromWhatsappId == null && data.messages[0]?.id == this.state.currentChannel?.id && data.messages[0]?.lastMessage?.isAutoReply != true;
            return { contacts: prevState.contacts };
          });
        });
      }
    })
  }

  getMessages() {
    const { socket } = this.context;
    const { currentChannel, currentContact } = this.state;
    socket.emit('getMessages', { contactId: currentChannel.id }, (err, res) => {
      if (err) {
        alert(err);
      } else {
        // console.log('getMessages: ' + currentChannel.id);
        // console.log(res);
        this.setState({ messages: res.reverse(), loading: false });
      }
    });

    socket.emit('getContactInfo', { whatsappId: currentContact.whatsappId }, (err, res) => {
      if (err) {
        alert(err);
      } else {
        this.setState({ clientInfo: res, loading: false }) ;
      }
    });
  }

  subscribeMessage() {
    const { socket } = this.context;
    const { currentChannel } = this.state;
    if (currentChannel) {
      var messageChannel = socket.subscribe('message/' + currentChannel.id);
      messageChannel.on('subscribe', (err, res) => {
        if (res) {
          messageChannel.watch((data) => {
            // console.log('Subscribe message: ');
            // console.log(data);
            if (data.fromWhatsappId != null) {
              this.props.pushNotification(data, currentChannel);
            }
            var message = this.state.messages.find(x => x.id == data.id);
            if (message == null) {
              var list = this.state.messages;
              list.push(data);
              this.setState({ messages: list });
            } else {
              var list = this.state.messages;
              var index = this.state.messages.indexOf(message);
              list[index].status = data.status;
              this.setState({ messages: list });
            }
          });
          this.getMessages();
        }
      })
    }
  }

  unSubscribeMessage() {
    const { socket } = this.context;
    socket.unsubscribe('message/' + this.state.currentChannel?.id);
    this.setState({ currentChannel: null })
  }

  mediaUploadEvent() {
    const { socket } = this.context;
    var fileReader = new FileReader();

    socket.on('mediaUpload/request', data => {
      // console.log('request data', data)
      // var media = this.state.uploadingMedia.find(x=>x.mediaId == data.mediaId).media;
      var media = this.state.uploadMedia;
      //use for progress
      var list = this.state.uploadingMedia;
      if (list.find(x => x.mediaId == data.mediaId) == null) {
        var item = data;
        item.media = media;
        list.push(item);
        this.setState({ uploadingMedia: list });
      }
      fileReader.onload = () => {
        // console.log('emit send', { mediaId: data.mediaId, byteStart: data.byteStart, fileSlice: fileReader.result })
        socket.emit('mediaUpload/send', {
          mediaId: data.mediaId,
          byteStart: data.byteStart,
          fileSlice: fileReader.result,
          channelPhoneNumber: this.state.currentChannel?.channelPhoneNumber,
        });
      };
      fileReader.readAsArrayBuffer(media.slice(data.byteStart, data.byteEnd));
    });

    socket.on('mediaUpload/process', data => {
      // console.log(data);
      var list = this.state.uploadingMedia;
      //update progress bar
      var item = list.find(x => x.mediaId == data.mediaId);
      var element = 'bar' + data.mediaId;
      var elem = document.getElementById(element);
      if (item && elem) {
        var width = (data.uploadedFileSize / item.byteEnd) * 100;
        elem.style.width = width + '%';
      }
    });

    socket.on('mediaUpload/finish', data => {
      // console.log(data);
      var media = this.state.uploadingMedia.find(x => x.mediaId == data.mediaId);
      // var media = this.state.uploadMedia;
      var type = media.media.type.split('/')[0];
      if (type != 'audio' && type != 'video' && type != 'image') type = 'document';
      var emitData = {
        toContactId: this.state.currentChannel.id,
        type: type,
        content: {
          mediaId: data.mediaId
        },
      }
      if (type == 'document') emitData.content['filename'] = media.media.name;
      // console.log(emitData);
      socket.emit('sendMessage', emitData, (err, res) => {
        if (err) {
          alert(err);
        }
      });
      //remove progress bar
      var list = this.state.uploadingMedia;
      var index = list.indexOf(media);
      if (index != -1) {
        list.splice(index, 1);
        this.setState({ uploadingMedia: list });
      }
    });

    socket.on('mediaUpload/error', data => {
      // console.log(data);
      alert('上載失敗!')
    });
  }

  onUploadMedia(file) {
    this.setState({ uploadMedia: file });
  }

  updateCurrentChannel(item, isExpired = false) {
    var user = JSON.parse(localStorage.getItem('userInfo'));
    var answeringByOtherUser = item?.messages?.find(m => m.answeringByUser != null && m.answeringByUser?.id != user.id)
    if (item && answeringByOtherUser != null /*&& !isExpired*/) {
      //disable if answering by other user
      alert('其他用戶解答中')
    } else {
      if (this.state.loading == false) {
        // console.log('Selected Chat: ' + item)
        if (item && this.state.currentContact?.whatsappId == item.whatsappId && this.state.currentContact?.channelPhoneNumber === item.channelPhoneNumber) return;

        this.resetInfiniteScroll();
        if (this.state.currentChannel != null) {
          this.unSubscribeMessage();
        }
        //if (!this.state.isSearching) {
        this.getRelatedContacts(item);
        //}
        const messageIndex = item?.messages?.findIndex(x => x.channelPhoneNumber == item?.channelPhoneNumber);
        this.setState({ currentContact: item, currentChannel: item?.messages[messageIndex] }, () => {
          if (item) {
            this.setState({ loading: true, clientInfo: null }, () => this.subscribeMessage());
          }
        });
      }
    }
  }

  updateFilterFollowUserId(id) {
    this.setState({ filterFollowUserId: id });
    //this.getContacts(this.state.chatListTabIndex, id);
  }

  getRelatedContacts(item) {
    const { socket } = this.context;
    const { contacts, chatListTabIndex } = this.state;
    socket.emit('getRelatedContacts', { whatsappId: item?.whatsappId, expired: chatListTabIndex === 2 ? '1' : '0',  }, (err, res) => {
      if (err) {
        alert(err)
      } else {
        let list = contacts?.slice() ?? [];
        let newMessages = [];
        const contactIndex = list?.findIndex(x => (x.whatsappId == item.whatsappId && x.channelPhoneNumber === item.channelPhoneNumber));
        if (contactIndex !== -1) {
          res?.map(e=>{
            if (e.messages.length) {
              newMessages.push(e.messages[0]);
            }
          });
          if (newMessages.length) list[contactIndex].messages = newMessages;
          this.setState({ contacts: list });
        }
      }
    });
  }

  renderLoading() {
    const { autoReply } = this.context;
    return (
      <section id="loading-container">
        {autoReply == true ?
          <>
            <img src={illust_autoreply} />
            <p>自動回覆開啟中，請先關閉</p>
          </>
          :
          <>
            <img src={illust_nomsg} />
            <p>請點選通話</p>
          </>
        }
      </section>
    )
  }

  scrollToBottom = () => {
    if (this.messagesEnd) {
      this.messagesEnd.scrollIntoView({ block: "end", behavior: "auto" }); //smooth
    }
  }

  loadMore() {
    const { socket } = this.context;
    const { currentChannel, messages } = this.state;
    if (currentChannel && messages.length) {
      socket.emit('getMessages', { contactId: currentChannel.id, beforeId: messages[0].id }, (err, res) => {
        if (err) {
          alert(err)
        } else {
          // console.log('getMessages')
          // console.log(res)
          if (res.length == 0) {
            // console.log('no more')
            this.setState({ hasMoreItems: false })
          } else {
            var resData = res.reverse();
            this.setState({ messages: [...resData, ...messages] })
          }
        }
      });
    }
  }

  renderUploadingMedia() {
    var list = [];
    this.state.uploadingMedia.map((item, index) => {
      // console.log(item)
      return (
        list.push(
          <div key={'upload' + index} id="message" className='self'>
            <div className='progress-container'>
              <div>上載中...</div>
              <div>{item.media.name}</div>
              <div className="progress-bar">
                <div id={'bar' + item.mediaId} className="progress"></div>
              </div>
            </div>
          </div>
        )
      )
    });
    return list;
  }

  renderMessagesContent(msgItems) {
    const { currentChannel } = this.state;
    var currentTimeStamp = null;
    var list = [];
    const brand = this.props?.channels?.find(x => x.channelPhoneNumber == currentChannel.channelPhoneNumber)?.name;
    const reverseMessages = msgItems?.slice()?.reverse();
    const unreplied = reverseMessages?.[0]?.fromWhatsappId != null ? msgItems?.length - reverseMessages.findIndex(x => x.fromWhatsappId == null) : null;
    msgItems.map((item, index) => {
      const name = item?.fromUser ? item.fromUser.name : currentChannel.name
      //render date
      // if (currentTimeStamp == null || moment(currentTimeStamp).isSame(moment(item.timestamp), 'day') == false) {
      //   currentTimeStamp = item.timestamp;
      //   list.push(<div key={item.timestamp} className="date">{moment(item.timestamp).format('YYYY-MM-DD HH:mm')}</div>)
      // }

      //render unreplied remind
      if (!!unreplied && index == unreplied) list.push(<div key="unreplied" className="unreplied"><img src={ic_mark} />以下為尚未解答的內容</div>)

      //render message by type
      const self = !!item.fromUser || item.toWhatsappId != null;
      const read = item.status == 'read' && item.fromUser && item == (msgItems.filter(x => x.fromUser != null).slice(-1)[0]);
      //render hsm message
      if (item.type == 'hsm' || item.type == "template") {
        list.push(<TextMessage key={item.id.toString()} text={item.content.body} self={true} read={read} status={item.status} />);
        //render user info
        list.push(<MessageInfo key={'u' + index.toString()} name={name} timestamp={item.timestamp} self={self} brand={brand} status={item.status} />);
        return;
      }

      switch (item.type) {
        case 'text':
          try {
            //Handle for sticker, check if content body is json format
            var body = JSON.parse(item.content.body);
            if (typeof body === 'object') {
              list.push(<TextMessage key={item.id.toString()} text="不支援的訊息類型，無法顯示。" self={self} read={read} status={item.status} errorMsg={true} />);
              return;
            }
          } catch (e) {
          }
          list.push(<TextMessage key={item.id.toString()} text={item.content.body} self={self} read={read} status={item.status} />);
          break;
        case 'image':
        case 'sticker':
        case 'voice':
        case 'audio':
        case 'video':
        case 'document':
          list.push(<MediaMessage key={item.id.toString()} item={item} self={self} read={read} />);
          break;
        case 'location':
          list.push(<LocationMessage key={item.id.toString()} content={item.content} self={self} read={read} status={item.status} />);
          break;
        case 'contacts':
          list.push(<ContactMessage key={item.id.toString()} contents={item.content} self={self} read={read} status={item.status} />);
          break;
        case 'call_log':
          list.push(<CallLogMessage key={item.id.toString()} content={item.content} self={self} read={read} status={item.status} />);
          break;
        default:
          list.push(<TextMessage key={item.id.toString()} text={JSON.stringify(item)} self={self} read={read} status={item.status} />);
      }
      //render user info
      list.push(<MessageInfo key={'u' + index.toString()} name={name} timestamp={item.timestamp} self={self} brand={brand} status={item.status} />);
    });
    return list;
  }

  renderMesssages() {
    const { messages } = this.state;
    if (messages) {
      var msgItems = this.renderMessagesContent(messages);
      var uploadingMediaItems = this.renderUploadingMedia();
      return (
        <div className="messages" ref={(el) => { this.messagesEnd = el; }}>
          {msgItems}
          {uploadingMediaItems}
        </div>
      );
    }
  }

  renderChatRoom() {
    // Load channel list from user info
    var userInfo = JSON.parse(localStorage.getItem('userInfo'));
    var viewPhoneNumber = userInfo?.viewPhoneNumber;

    const { currentContact, currentChannel, messages, contacts, chatListTabIndex } = this.state;
    const { defaultPhoneNumberMaskLength } = this.context;
    //var lastMessage = messages ? messages.filter(x => x.fromUser == null).slice(-1)[0] : null;
    //messages?.some(y => y.lastMessage.fromWhatsappId == null && moment.duration(moment().diff(moment.utc(y.lastMessage.timestamp))).asHours() >= 24)
    var isExpired = chatListTabIndex == 2 /*|| (lastMessage && lastMessage.timestamp ? (moment.duration(moment().diff(moment.utc(lastMessage?.timestamp))).asHours() >= 24) : true)*/;
    const channels = contacts?.find(x => x.whatsappId == currentContact?.whatsappId && x.channelPhoneNumber === currentContact?.channelPhoneNumber);
    //const channels = currentContact;

    var phoneNumber = '';

    if(!viewPhoneNumber) {
      // Masked it
      phoneNumber = Regex.maskPhoneNumber(channels?.messages[0]?.name, defaultPhoneNumberMaskLength, 'gm');
    } else {
      phoneNumber = `${channels?.messages[0]?.name ?? ''} (${channels?.whatsappId || ''})`;
    }

    const followerUserName = currentChannel?.followUser ? ` ${currentChannel?.followUser?.name}` : '';

    return (
      <React.Fragment>
        <section id="header-container">
          <div className="brand-list">
            <div>
              <div id="name-container">
                <p className="user-name">{phoneNumber}</p>
                <div id="follow-container">
                  <p className="follow-name">負責人:{followerUserName}</p>
                  <a onClick={() => this.setState({popupFollowUserEdit: true})} >更改</a>
                </div>
              </div>
              <Tabs
                value={Math.max(channels?.messages?.findIndex(x => x.id == currentChannel.id), 0)}
                onChange={(event, newValue) => {
                  this.unSubscribeMessage()
                  this.setState({ currentChannel: channels?.messages[newValue], messages: [], checkingMessage: false }, () => this.subscribeMessage())
                }}
              >
                {
                  channels?.messages?.map((msg, index) => {
                    const channel = this.props.channels.find(x => x.channelPhoneNumber == msg.channelPhoneNumber)
                    const unreply = msg?.lastMessage?.fromWhatsappId != null;
                    return (
                      <Tooltip key={`btab_${index}`} title={channel?.name}>
                        <Tab label={/*<Badge badgeContent={msg.unreadCount}></Badge>*/<Badge variant="dot" invisible={!unreply}></Badge>} icon={<img className="brand-icon" src={channel?.logo} />} />
                      </Tooltip>
                    )
                  })
                }
              </Tabs>
            </div>
            <button className="close" onClick={() => { this.updateCurrentChannel(null) }}>暫停解答</button>
          </div>
          <div className="linear-border"></div>
        </section>
        <section id="messages-container" ref={(ref) => this.scrollParentRef = ref} onScroll={this.handleScroll}>
          {this.renderMesssages()}
        </section>
        <MessageBox currentChannel={currentChannel} currentContact={currentContact} isExpired={isExpired} onUploadMedia={this.onUploadMedia.bind(this)} getMessages={this.getMessages.bind(this)} />
      </React.Fragment>
    )
  }

  renderClientInfo() {
    const { socket } = this.context;
    const { currentChannel, clientInfo } = this.state;

    return (
      <ClientInfo item={currentChannel} data={clientInfo} />
    )
  }

  handleLogout() {
    const { socket } = this.context;
    Auth.logout(socket, () => {
      this.props.history.replace('/login');
      cookies.remove('user');
    });
  }

  appendContacts = (contacts) => {
    this.setState({ contacts: this.state.contacts.concat(contacts) });
  }

  updateContacts = (contacts) => {
    this.setState({ contacts: contacts });
  }

  updateIsSearching = (status) => {
    this.setState({ isSearching: status });
  }

  updateBackdrop = (status) => {
    this.setState({ backdropOpen: status });
  }

  render() {
    const { autoReply, openDrawer } = this.context;
    const { logoutAnchorEl, contacts, currentContact, currentChannel, chatListTabIndex, backdropOpen } = this.state;
    var isChatLoaded = this.state.currentChannel != null && this.state.loading == false;
    const openLogout = Boolean(logoutAnchorEl);

    // Load channel list from user info
    var userInfo = JSON.parse(localStorage.getItem('userInfo'));
    var channelList = userInfo?.channels ?? [];

    var channelPhoneNumberList = [];
    var fullContactList = [];
    let pendingList = [];
    let processingList = [];
    let expiredList = [];

    // Check if login user is not admin
    if(!userInfo?.administrator) {
      for(var i = 0; i < channelList.length; i++) {
        channelPhoneNumberList.push(channelList[i].channelPhoneNumber.trim());
      }

      if(typeof(contacts) !== null) {
        // Filter out the contacts record if no access on channels
        fullContactList = contacts?.slice();
        fullContactList = fullContactList?.filter((contact) => channelPhoneNumberList.includes(contact?.messages?.[0]?.channelPhoneNumber.trim()));
      }
    } else {
      // Array for admin
      fullContactList = contacts?.slice();
    }

    // Assign value to specific tabs
    if(fullContactList?.length > 0) {
      let list = fullContactList?.slice();
      if (chatListTabIndex === 0) {
        pendingList = list.filter(x => x.messages?.some(y => y.unreadCount > 0 || (y.lastMessage?.fromWhatsappId !== null || y.lastMessage?.isAutoReply === true)))
      } else if (chatListTabIndex === 1) {
        processingList = list.filter(x => x.messages?.some(y => y.unreadCount === 0 && y.lastMessage?.isAutoReply !== true && y.lastMessage?.fromWhatsappId == null))
      } else if (chatListTabIndex === 2) {
        expiredList = list;
      }
    }

    return (
      <div id="inbox-container" className={!openDrawer ? 'openDrawer' : ''}>
        <TopBar title="訊息解答" {...this.props} {...this.context} />
        <div id="chat-container">
          <ChatList
            data={fullContactList} 
            pendList={pendingList}
            processList={processingList}
            expiredList={expiredList}
            currentContact={currentContact} 
            currentChannel={currentChannel} 
            tabIndex={chatListTabIndex}
            onSelect={(item, isExpired) => { this.updateCurrentChannel(item, isExpired) }}
            autoReply={this.props.autoReply}
            defaultPhoneNumberMaskLength={this.props.defaultPhoneNumberMaskLength}
            highlightedContacts={this.props.highlightedContacts}
            loading={this.state.loadingContacts}
            appendContacts={this.appendContacts}
            onUpdateTabIndex={(index) => {
                this.unSubscribeMessage();
                this.getContacts(index);
              }
            }
            contacts={this.state.contacts}
            updateContacts={this.updateContacts}
            updateIsSearching={this.updateIsSearching}
            channelList={this.state.channelList}
            updateBackdrop={this.updateBackdrop}
            searchChatList={this.searchChatList.bind(this)}
            staffList={this.state.staffList}
            updateFilterFollowUserId={this.updateFilterFollowUserId.bind(this)}
            filterFollowUserId={this.state.filterFollowUserId}
          />
          {isChatLoaded && autoReply == false ?
            <div style={{ display: 'flex', flex: 0.7 }}>
              <div id="user-message-container" style={{ flex: 1 }} /*className={!isChatLoaded || autoReply == true ? 'gradient' : undefined}*/>
                {this.renderChatRoom()}
              </div>
              {this.renderClientInfo()}
            </div>
            :
            <div id="user-message-container">
              {this.renderLoading()}
            </div>
          }
        </div>
        <Popover
          id='logout-popover'
          open={openLogout}
          anchorEl={logoutAnchorEl}
          onClose={() => this.setState({ logoutAnchorEl: null })}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <p onClick={() => this.handleLogout()}>登出</p>
        </Popover>
        <FollowUserEdit
          open={this.state.popupFollowUserEdit}
          onClose={() => this.setState({popupFollowUserEdit: false})}
          staffList={this.state.staffList}
          currentChannel={this.state.currentChannel}
          context={this.context}
        />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropOpen}
          onClick={() => this.updateBackdrop(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div >
    )
  }
}

ChatRoom.contextTypes = {
  socket: PropTypes.object.isRequired,
  autoReply: PropTypes.string,
  defaultPhoneNumberMaskLength: PropTypes.string,
  highlightedContacts: PropTypes.arrayOf(PropTypes.string),
  openDrawer: PropTypes.bool,
  setState: PropTypes.func,
};

const mapStateToProps = (state) => {
  return { channels: state.reducer.channels }
}

export default connect(mapStateToProps)(AppNotification(withRouter(ChatRoom)));