import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter, Link } from "react-router-dom";
import loading from '../../assets/img/chat/icon-loading.png';
import ic_menu_collapse from '../../assets/img/menu/ic_menu_collapse.svg';
import reply_off from '../../assets/img/menu/reply_off.svg';
import reply_on from '../../assets/img/menu/reply_on.svg';
import { Drawer, IconButton, List, ListItem, ListItemIcon, Modal, Button } from '@mui/material';
import { routes } from '../../RouterM';
import { Auth } from '../../Auth';
import { connect } from 'react-redux';
import { onUpdateChannels } from '../../redux/action';
import config from '../../config';

class UserMenu extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showConfirmPopup: false,
    };
  }

  componentDidMount() {
    if (Auth.isLoggedIn() === true) {
      this.getAppSetting();
    }
  }

  componentWillUnmount() {
    const { socket } = this.context;

    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };

    socket.unsubscribe('appSetting');
  }

  getAppSetting() {
    const { socket, setState } = this.context;
    const { onUpdateOnline, onUpdateChannels } = this.props;
    socket.emit('getAppSettings', null, (err, res) => {
      if (err) {
        alert(err)
      } else {
        // console.log(res);
        if (onUpdateOnline) onUpdateOnline(res[0].value);
        setState({ autoReply: res[0].value });
        this.forceUpdate();
      }
    });
    var settingChannel = socket.subscribe('appSetting');
    settingChannel.on('subscribe', (err, res) => {
      if (res) {
        settingChannel.watch((data) => {
          // console.log('Subscribe contact: ');
          // console.log(data);
          if (onUpdateOnline) onUpdateOnline(data.value);
          setState({ autoReply: data.value });

          if (data.value == true) {
            //unsubscribe all subscribed channels
            var subscriptions = socket.subscriptions();
            subscriptions.forEach((channel, index) => {
              if (channel != 'appSetting') socket.unsubscribe(channel);
            });
          } else {
            //reload to get lastest contacts
            window.location.reload();
          }
        });
      }
    })
    socket.emit('getChannels', null, (err, res) => {
      if (err) {
        alert(err)
      } else {
        // console.log('getChannels')
        // console.log(res)
        onUpdateChannels(res)
      }
    })
  }

  setAppSetting(value) {
    const { socket } = this.context;
    const { autoReply } = this.context;
    if (value != autoReply) {
      socket.emit('setAppSettings', { autoReply: value }, (err, res) => {
        this.setState({ showConfirmPopup: false });
      });
    }
  }

  renderPopup() {
    const { autoReply } = this.context;
    const title = autoReply == true ? '是否確定關閉自動回覆' : '是否確定開啟自動回覆';
    const setting = autoReply == true ? '0' : '1';
    return (
      <div>
        <div className="text">{title}</div>
        <div className='options'>
          <Button variant="contained" className="modalButton" onClick={() => this.setState({ showConfirmPopup: false })}>取消</Button>
          <Button variant="contained" className="modalButton" style={{ backgroundColor: '#0059c1', color: '#fff', marginLeft: '16px' }} onClick={() => this.setAppSetting(setting)}>確定</Button>
        </div>
      </div>
    )
  }

  render() {
    const { showConfirmPopup } = this.state;
    const { autoReply, openDrawer, setState } = this.context;

    var user = JSON.parse(localStorage.getItem('userInfo'));
    return (
      <Drawer className="drawer" variant="persistent" anchor="left" open={openDrawer}>
        <div id="user-menu">
          <div>
            <IconButton onClick={() => setState({ openDrawer: false })}>
              <img src={ic_menu_collapse} />
            </IconButton>
            {/* {user &&
              <div style={{ margin: '10px 0px 8px 24px' }}>
                <div className="user-name">{user?.id}</div>
                <div className="user-name">{user?.lastLoginIp}</div>
              </div>
            } */}
            <div className="auto-reply-container" onClick={() => this.setState({ showConfirmPopup: true })}>
              <span>自動回覆</span>
              <img src={autoReply == true ? reply_on : reply_off} />
            </div>
            <div>
              {
                routes?.filter(x => x?.menu != null)?.map((v, i) => {
                  const isActive = this.props.location.pathname.match(v.path)
                  const isValid = v?.adminRight ? v.adminRight == true && user?.administrator == true : true;
                  if (!isValid) return
                  return (
                    <NavLink
                      key={i}
                      to={v.path}
                      isActive={(match, location) => {
                        if (!match) return false;
                        return isActive
                      }}
                    >
                      <List className='list'>
                        <ListItem button>
                          <ListItemIcon>
                            <img src={isActive ? v.menu?.active_icon : v.menu?.icon} />
                          </ListItemIcon>
                          <div>{v.menu?.text}</div>
                        </ListItem>
                      </List>
                    </NavLink>
                  )
                })
              }
            </div>
          </div>
          <div className="version">v{config?.version}</div>
          <Modal
            className="modal"
            open={showConfirmPopup}
            onClose={() => this.setState({ showConfirmPopup: false })}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div style={{ width: 449, padding: 36, borderRadius: 18, backgroundColor: '#fff' }}>
              <img src={loading} />
              {this.renderPopup()}
            </div>
          </Modal>
        </div>
      </Drawer>
    )
  }
}

UserMenu.contextTypes = {
  socket: PropTypes.object.isRequired,
  autoReply: PropTypes.string,
  openDrawer: PropTypes.bool,
  setState: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateChannels: (res) => {
      dispatch(onUpdateChannels(res));
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserMenu));