import React from 'react';
import moment from 'moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Img from 'react-image';
import mime from 'mime-types';
import Linkify from 'react-linkify';

class MediaMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: ''
    };
  }

  downloadMedia(path, type, getUrl){
    const { id, content } = this.props.item;
    const { imageUrl } = this.state;
    let downloadPath = '';
    
    if(type == 'image' || type == 'sticker') {
      downloadPath = imageUrl ? imageUrl : path;
    } else {
      downloadPath = path;
    }

    var token = localStorage.getItem('socketCluster.authToken');
    var options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
    };
    fetch(downloadPath, options).then(response => response.blob())
    .then(file => {
      var url = URL.createObjectURL(file);
      // console.log('url: ' + url);
      if(getUrl){
        var media = document.getElementById(id);
        if(media) {
          media.src = url;
          media.load();
        }
      } else {
        var file = document.createElement('a');
        let fileExtension = '';
        if(content.mimeType.includes('codec')) {
          // Check if mime contains codec string
          fileExtension = content.mimeType.split('codecs=')[1];
        } else if(content.mimeType) {
          if (type !== 'video') {
            // Get extension for non-video type
            fileExtension = mime.extension(content.mimeType);
          } else {
            // Get extension for video type as mime type from content always return text/plain type
            let fileMimeTypeWithExtension = mime.lookup(content.link);
            fileExtension = mime.extension(fileMimeTypeWithExtension);
          }
        }
        var fileFormat = type == 'video' || type == 'voice' || type == 'audio' ? '.' + fileExtension : '';
        var filename = type == 'document' ? content.filename : moment().format('X') + fileFormat;
        file.href = url;
        file.download = filename;
        file.click();
      }
    })
  }

  getFileSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  };

  reloadImg(image, id, url) {
    if(!image.complete) {
      setTimeout(function (){
        // image.src = url +'?' + +new Date;
        image.src = url;
        // var img = document.getElementById(id);
        // if(img) img.src = url;
        var element = document.getElementById(id);
        if(element) {
          element.removeChild(element.childNodes[0]); 
          var img = document.createElement('img');
          img.className = 'img'
          img.src = url;
          element.appendChild(img);
        }
        // <img id={id} className='img' src={content.thumbnailUrl} />
       }, 1000);
    }
  }

  onImageUrlRefresh() {
    const { content } = this.props.item;

    let thumbnailUrl = new URL(content.thumbnailUrl);
    var token = localStorage.getItem('socketCluster.authToken');
    thumbnailUrl.searchParams.set('token', token);

    this.setState({ imageUrl: thumbnailUrl.toString() });
  }

  render() {
    const { self, read, process } = this.props;
    var {id, content, type, status} = this.props.item;
    return(
      <div id="message" className={`${'media-message'} ${self ? 'self' : ''}`}>
        <div className='row-container'>
          <span className={`${'media-container'} ${type=='image' ? 'full-width' : ''}`}>
            {(type == 'image' || type == 'sticker') &&
              // <LazyLoadImage className='img' src={content.thumbnailUrl} effect="blur" height='296px' visibleByDefault={true} />
              <Img
                className='img' 
                src={this.state.imageUrl !== '' ? this.state.imageUrl : content.thumbnailUrl} 
                onError={this.reloadImg(this, id, content.thumbnailUrl)} 
                loader={<div className='img loader-container'><div className='loader'></div></div>} 
                unloader={<div id={id} className='img loader-container'><div className='loader'></div></div>} 
              />
            }
            {type == 'document' &&
              <div className='document'>{content.filename}</div>
            }
            {(type == 'voice' || type == 'audio') &&
              <audio id={id} controls src={this.downloadMedia(content.fileUrl, type, true)} />
            }
            {type == 'video' &&
              <video id={id} controls src={this.downloadMedia(content.fileUrl, type, true)} type={content.mimeType} />
            }
            {content.caption &&
              <Linkify properties={{ target: '_blank', style: { color: '#528fe6' } }}><span className="caption">{content.caption}</span></Linkify>
            }
            <div style={{flexDirection: 'row'}}>
              {(type == 'image' || type == 'sticker') &&
                <button className="download" onClick={() => {this.onImageUrlRefresh()}}>刷新</button>
              }
              <button className="download" onClick={()=>{ this.downloadMedia(content.fileUrl, type) }}>下載({this.getFileSize(content.fileSize)})</button>
            </div>
          </span>
          {status == 'failed' && <p className="failed">!</p>}
        </div>
        {/* {read && <p className="read">已讀</p>} */}
      </div>
    )
  }
}

export default MediaMessage;