import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import TopBar from '../../components/TopBar';
import StaffList from '../../components/permission/StaffList';
import BrandList from '../../components/permission/BrandList';

class PermissionSetting extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    const { openDrawer } = this.context;

    return (
      <div id="permission-setting" className={!openDrawer ? 'openDrawer' : ''}>
        <TopBar title="權限設定" {...this.props} {...this.context} />
        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, overflowY: 'hidden' }}>
          <StaffList />
          <BrandList />
        </div>
      </div>
    )
  }
}

PermissionSetting.contextTypes = {
  socket: PropTypes.object.isRequired,
  openDrawer: PropTypes.bool,
  setState: PropTypes.func,
};

export default withRouter(PermissionSetting);