import React, { useState } from 'react';

import * as data from '../assets/reply.json';
import SearchBar from '../components/SearchBar';

export default function TemplateMessageList(props) {
  const { currentChannel, onClick } = props;
  const [selectedTab, setSelectedTab] = useState(0)
  const [selectedMsg, setSelectedMsg] = useState([])
  const [replyMessage, setReplyMessage] = useState(data.reply)
  const [search, setSearch] = useState('');

  const onSearchChange = (value) => {
    setSelectedTab(null);

    var list = data.reply;
    if (value.trim().length > 0) {
      list = list.filter(x => x.tabTitle.toUpperCase().includes(value.toUpperCase()))
    }
    setReplyMessage(list)
    setSearch(value);
  }

  const onSelectMsg = (item) => {
    var list = selectedMsg.slice();

    if (selectedMsg.includes(item)) {
      list.splice(selectedMsg.indexOf(item), 1);
      setSelectedMsg(list)
      onClick(list.map(x => x.message).join('\n'));
    } else {
      list.push(item);
      setSelectedMsg(list)
      onClick(list.map(x => x.message).join('\n'));
    }
  }

  const renderTab = () => {
    const listItems = replyMessage?.map((item, index) =>
      <span key={`templateMessageListTab_${index.toString()}`} onClick={() => setSelectedTab(index)} className={selectedTab == index ? 'active' : ''}>{item.tabTitle}</span>
    )
    return (
      <div className='tab-container'>
        <SearchBar 
        keyword={search}
        onChange={(value) => onSearchChange(value)} customStyle={{ flex: 0 }} />
        {listItems}
      </div>
    );
  }

  const renderList = (messages) => {
    const listItems = messages?.map((item, index) => {
      item.message = item.message.replace("{{NAME}}", currentChannel.name);
      return (
        <li key={`templateMessageListList_${index.toString()}`} onClick={() => { onSelectMsg(item); }} className={selectedMsg.includes(item) ? 'active' : ''}>
          <div className='title'>{item.ref}</div>
          <div className='message'>{item.message}</div>
        </li>
      )
    })
    return (
      <ul>
        {listItems}
      </ul>
    );
  }

  return (
    <div id="template-message">
      <div className='message-container'>
        {renderTab()}
        <div className='list-container'>
          {selectedTab != null && renderList(replyMessage[selectedTab].messages)}
        </div>
      </div>
    </div>
  )
}