import React from 'react';

class ContactMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { contents, self, read, status } = this.props;
    if(contents){
      var contactsList = contents.map((contact, index) => {
        return(
          <div id="message" key={index.toString()} className={`${'contact-message'} ${self ? 'self' : ''}`}>
            <div className='row-container'>
              <span>
                {contact.name.first_name && <p>姓: {contact.name.first_name}</p>}
                {contact.name.last_name && <p>名: {contact.name.last_name}</p>}
                {contact.phones && contact.phones.map((item, index)=>{
                  return <p key={item.wa_id}>電話: {item.phone}</p>
                })}
                {contact.emails && contact.emails.map((item, index)=>{
                  return <p key={item.email}>電郵: {item.email}</p>
                })}
                {contact.addresses && contact.addresses.map((item, index)=>{
                  return <p key={'address'+index.toString()}>地址: {item.city} {item.country} {item.country_code} {item.state} {item.street}</p>
                })}
              </span>
              {status == 'failed' && <p className="failed">!</p>}
            </div>
            {/* {read && <p className="read">已讀</p>} */}
          </div>
        )
      })
      return contactsList;
    } else
      return null;
  }
}

export default ContactMessage;