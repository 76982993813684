var CryptoJS = require("crypto-js");
var rememberMe = localStorage.getItem('rememberMe');
var secretKey = Math.random().toString(36).slice(-8);
var PropTypes = require('prop-types');

function isLoggedIn() { return localStorage.getItem('userInfo') != null; }

function login(name, password, autoLogin, socket, cookies, callback) {
  var credentials = {
    username: name,
    password: password
  };
  socket.emit('login', credentials, (err, res) => {
    if (err) {
      alert(err)
    } else {
      // console.log(res)
      if (autoLogin === true) {
        // Encrypt
        var encryptData = CryptoJS.AES.encrypt(JSON.stringify(credentials), secretKey).toString();
        // localStorage.setItem('user', JSON.stringify(encryptData));
        // localStorage.setItem('user', encryptData);
        cookies.set('user', encryptData, { path: '/' });
        cookies.set('sk', secretKey);
      }
      localStorage.setItem('userInfo', JSON.stringify(res));
      localStorage.setItem('rememberMe', autoLogin);
      callback();
    }
  })
};

function logout(socket, callback) {
  localStorage.clear();

  //unsubscribe all subscribed channels
  var subscriptions = socket.subscriptions();
  subscriptions.forEach((channel, index) => {
    socket.unsubscribe(channel);
  });
  socket.emit('logout');

  callback();
};

function autoLogin(socket, cookies, callback) {
  // const user = JSON.parse(localStorage.getItem('user'));
  // const encryptUser = localStorage.getItem('user');
  const encryptUser = cookies.get('user');
  const key = cookies.get('sk');
  if (encryptUser) {
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(encryptUser, key);
    var user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    login(user.username, user.password, true, socket, cookies, callback);
  }
}

export const Auth = { isLoggedIn, rememberMe, autoLogin, login, logout };

Auth.childContextTypes = {
  socket: PropTypes.object,
};