import React from 'react';

class CallLogMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { content, self, read, status } = this.props;
    return (
      <div id="message" className={`call-log-message${self ? ' self' : ''}`}>
        <div className='row-container'>
          <span>📞 通話</span>
          {status == 'failed' && <p className="failed">!</p>}
        </div>
        {/* {read && <p className="read">已讀</p>} */}
      </div>
    )
  }
}

export default CallLogMessage;