export function maskPhoneNumber(phoneNumber, maskLength, regexOptions) {
    try{
        if(phoneNumber.length > 0) {
            var reg = RegExp('(?<=\\d{' + (phoneNumber?.length - maskLength) + '})\\d', regexOptions);
            return phoneNumber.replace(reg, "*");
        }
        return '';
    } catch(err) {
        // console.log('Error: ');
        // console.log(err);
        return '';
    }
}