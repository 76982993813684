import React from 'react';
import './App.css';
import Socket from './Socket';
import RouterM from './RouterM';

export default function App(props) {

  return (
    <Socket>
      <RouterM />
    </Socket>
  )
}