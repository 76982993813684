import React, { useState } from 'react';
import { Auth } from '../Auth';
import Cookies from 'universal-cookie';
import { Popover } from '@mui/material';
import ic_iser from '../assets/img/setting/ic_iser.svg';
import ic_menu_expand from '../assets/img/menu/ic_menu_expand.svg';

export default function TopBar(props) {
  const { title, socket, history, openDrawer, setState } = props;
  const cookies = new Cookies();
  const [logoutAnchorEl, setLogoutAnchorEl] = useState(null)
  var user = JSON.parse(localStorage.getItem('userInfo'));

  const handleLogout = () => {
    Auth.logout(socket, () => {
      history.replace('/login');
      cookies.remove('user');
    });
  }

  return (
    <div id="top-bar">
      <div>
        {openDrawer == false && <img src={ic_menu_expand} onClick={() => setState({ openDrawer: true })} />}
        <div>{title}</div>
      </div>
      <div style={{ cursor: 'pointer' }}>
        <img src={ic_iser} />
        <div className="username" onClick={(event) => setLogoutAnchorEl(event.currentTarget)}>{user?.name}</div>
      </div>
      <Popover
        id='logout-popover'
        open={Boolean(logoutAnchorEl)}
        anchorEl={logoutAnchorEl}
        onClose={() => setLogoutAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <p onClick={handleLogout}>登出</p>
      </Popover>
    </div>
  )
}