import React from 'react';

class LocationMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { content, self, read, status } = this.props;
    var link = 'https://maps.google.com/?q=' + content.latitude + ',' + content.longitude;
    return(
      <div id="message" className={`${'location-message'} ${self ? 'self' : ''}`}>
        <div className='row-container'>
          <a target="_blank" href={link}>位置 {content.latitude} {content.longitude}</a>
          {status == 'failed' && <p className="failed">!</p>}
        </div>
        {/* {read && <p className="read">已讀</p>} */}
      </div>
    )
  }
}

export default LocationMessage;