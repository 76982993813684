import React, { } from 'react';
import { Modal, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '32px',
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    color: '#2c2c2c',
    marginBottom: '16px',
  },
  text: {
    fontSize: 14,
    color: '#2c2c2c',
    marginBottom: '23px',
  },
  name: {
    fontWeight: 'bold',
  },
  modalButton: {
    padding: '8px 27px',
    borderRadius: '10px',
    backgroundColor: '#dedede',
    fontSize: '14px',
    fontWeight: '500',
    color: '#2c2c2c',
    boxShadow: 'none',
    '& .Mui-disabled': {
      opacity: 0.45,
    }
  },
})

export default function DeleteStaffModal(props) {
  const { item, onConfirm, onCancel, openPopup } = props;
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={openPopup}
      onClose={() => { }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={{ width: 500, maxHeight: 500, padding: 36, borderRadius: 18, backgroundColor: '#fff' }}>
        <div className={classes.title}>刪除員工</div>
        <div style={{ margin: '36px 0 56px', textAlign: 'center' }}>
          <div className={classes.text}>確定刪除以下員工？</div>
          <div className={`${classes.text} ${classes.name}`}>{item?.name} <span style={{ fontWeight: 'normal' }}>{item?.username}  {item?.dept}</span></div>
        </div>
        <div className={classes.buttonContainer}>
          <Button variant="contained" className={classes.modalButton} onClick={onCancel}>取消</Button>
          <Button variant="contained" className={classes.modalButton} style={{ backgroundColor: '#0059c1', color: '#fff', marginLeft: '16px' }} onClick={onConfirm}>確定</Button>
        </div>
      </div>
    </Modal>
  )
}