import React from 'react';
import 'emoji-mart/css/emoji-mart.css'
import moment from 'moment';
import 'moment/locale/zh-hk'
import ic_deliver from '../assets/img/ic_chatstatus/ic_deliver.svg';
import ic_read from '../assets/img/ic_chatstatus/ic_read.svg';
import ic_sent from '../assets/img/ic_chatstatus/ic_sent.svg';
import * as Regex from '../util/regex';

class ChatListCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  addEmoji(emoji) {
    console.log(emoji.native)
  }

  renderLastMessage(lastMessage) {
    var content;
    var inOutBound = lastMessage?.fromWhatsappId ? '收到' : '已傳送';
    switch (lastMessage?.type) {
      case 'text':
      case 'template':
        try {
          //Handle for sticker, check if content body is json format
          var body = JSON.parse(lastMessage.content.body);
          if (typeof body === 'object') {
            content = "不支援的訊息類型，無法顯示。";
            return
          }
        } catch (e) {
        }
        content = lastMessage.content.body;
        break;
      case 'image':
        content = '你' + inOutBound + '照片';
        break;
      case 'sticker':
        content = '你' + inOutBound + '貼圖';
        break;
      case 'location':
        content = '你' + inOutBound + '位置';
        break;
      case 'contacts':
        content = '你' + inOutBound + '聯絡人';
        break;
      case 'voice':
      case 'audio':
        content = '你' + inOutBound + '語音';
        break;
      case 'video':
        content = '你' + inOutBound + '影片';
        break;
      case 'document':
        content = '你' + inOutBound + '文件';
        break;
      case 'call_log':
        content = (lastMessage?.fromWhatsappId ? '撥入' : '撥出') + '通話';
        break;
    }
    return content;
  }

  renderTime(lastMessageAt) {
    const lastMessageAtDate = moment(lastMessageAt).format('YYYYMMDD');
    if (moment().diff(moment(lastMessageAtDate), 'days') < 1) {
      return moment(lastMessageAt).format('a HH:mm');
    } else if (moment().diff(lastMessageAtDate, 'days') < 2) {
      return '昨天';
    } else if (moment().diff(lastMessageAtDate, 'days') < 7) {
      return moment.weekdays(moment(lastMessageAt).day());
    } else {
      return moment(lastMessageAt).format('DD/MM/YYYY');
    }
  }

  renderStatus(status) {
    if (status?.toLowerCase() == 'sent') {
      return <span className="status"><img src={ic_sent} /></span>
    } else if (status?.toLowerCase() == "delivered") {
      return <span className="status"><img src={ic_deliver} /></span>
    } else if (status?.toLowerCase() == "read") {
      return <span className="status"><img src={ic_read} /></span>
    }
  }

  renderFollowUser(messageIndex) {
    var { item } = this.props;
    if (item?.messages?.[messageIndex]?.followUser?.name) {
      return <div className="row">
        <span className="followUser">負責人: {item?.messages?.[messageIndex]?.followUser?.name}</span>
      </div>
    }
  }

  render() {
    // Load channel list from user info
    var userInfo = JSON.parse(localStorage.getItem('userInfo'));
    var viewPhoneNumber = userInfo?.viewPhoneNumber;
    
    var { item, onClick, active, currentChannel, isExpired = false, defaultPhoneNumberMaskLength, highlightedContacts, channelList } = this.props;
    var messageIndex = item.messages?.findIndex(x => x.channelPhoneNumber == item?.channelPhoneNumber);
    //var message = (active == false || item?.messages[0]?.id == currentChannel?.id) ? item?.messages?.[0] : currentChannel;
    const message = item?.messages?.[messageIndex];
    const answeringByUser = item?.messages?.find(x => x.answeringByUser != null)?.answeringByUser?.name;
    const unreadCount = item.messages.reduce((acc, cur) => acc + cur?.unreadCount, 0);

    var phoneNumber = '';

    if(!viewPhoneNumber) {
      // Masked it
      phoneNumber = Regex.maskPhoneNumber(message?.name, defaultPhoneNumberMaskLength, 'gm');
    } else {
      phoneNumber = message?.name;
    }

    const channel = channelList?.find(x => x.channelPhoneNumber === item?.channelPhoneNumber);

    return (
      <li id="chat-list-cell" key={item.whatsappId.toString()} onClick={onClick} className={!!highlightedContacts && [highlightedContacts.includes(item.whatsappId.toString()) ? 'highlighted' : '', active ? 'active' : ''].join(' ')}>
        {/* <img className="profile-pic" /> */}
        <div>
          <div className="row">
            <span className="name">{phoneNumber}</span>
            <span className="time">{this.renderTime(message?.lastMessageAt)}</span>
          </div>
          <div className="row">
            {message?.lastMessage?.fromWhatsappId == null && this.renderStatus(message?.lastMessage?.status)}
            <span className="message">{this.renderLastMessage(message?.lastMessage)}</span>
            {unreadCount > 0 && <span className="unread">{unreadCount}</span>}
            {unreadCount == 0 && message?.lastMessage?.fromWhatsappId != null && <span className="unanswer">未解答</span>}
          </div>
          <div className="row">
            <span className="channel">頻道: {channel?.name}</span>
          </div>
          {this.renderFollowUser(messageIndex)}
          {/*!isExpired && */<div className="answering">{answeringByUser && (answeringByUser + "解答中...")}</div>}
        </div>
      </li>
    )
  }
}

export default ChatListCell;