import React, { useState, useEffect, useCallback } from "react";
import { Modal, TextField, Button, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ic_add from "../../assets/img/setting/ic_add.svg";
import ic_trash_w from "../../assets/img/setting/ic_trash_w.svg";
import ic_trash_r from "../../assets/img/setting/ic_trash_r.svg";
import btn_add from "../../assets/img/setting/btn_add.svg";
import btn_remove from "../../assets/img/setting/btn_remove.svg";
import SearchBar from "../../components/SearchBar";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "32px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "20px 0",
  },
  title: {
    fontSize: "14px",
    color: "#6d6d6d",
    flex: 1,
  },
  modalButton: {
    padding: "8px 27px",
    borderRadius: "10px",
    backgroundColor: "#dedede",
    fontSize: "14px",
    fontWeight: "500",
    color: "#2c2c2c",
    boxShadow: "none",
    "& .Mui-disabled": {
      opacity: 0.45,
    },
  },
  brandButton: {
    borderRadius: "6px",
    border: "solid 1px #dedede",
    backgroundColor: "#fff",
    fontSize: "14px",
    fontWeight: "500",
    color: "#2c2c2c",
    boxShadow: "none",
  },
  deleteBrandButton: {
    padding: "4px 10px",
    backgroundColor: "#ff5f5f",
    fontSize: "13px",
    fontWeight: "500",
    color: "#fff",
    boxShadow: "none",
  },
  deleteStaffButton: {
    borderRadius: "10px",
    color: "#ff5f5f",
  },
  textInput: {
    flex: 2,
    backgroundColor: "#f6f6f6",
    "& .MuiInputBase-root": {
      fontSize: "14px",
    },
  },
  order: {
    padding: "6px 15px",
    borderRadius: "6px",
    border: "solid 1px #dedede",
    backgroundColor: "#f6f6f6",
    fontSize: "14px",
    fontWeight: "500",
    color: "#2c2c2c",
  },
  brandName: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#2c2c2c",
  },
  ul: {
    "& :hover": {
      textDecoration: "none",
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  brandItem: {
    display: "flex",
    padding: "10px 0",
    borderBottom: "solid 1px #dedede",
    cursor: "pointer",
    alignItems: "center",
  },
  brandIcon: {
    width: "32px",
    height: "32px",
    border: "solid 1px #dedede",
    borderRadius: "50%",
    marginRight: "16px",
  },
});

export default function CreateStaffModal(props) {
  const { item, onConfirm, onCancel, onDelete, openPopup } = props;
  const classes = useStyles();

  // For password field check only
  const [isAdmin, setIsAdmin] = useState(false);
  const [loginUserId, setLoginUserId] = useState(false);

  const [id, setUserId] = useState(null);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // const [dept, setDept] = useState("")
  const [brands, setBrands] = useState([]);
  const [search, setSearch] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isCreateNew = item == null;
  const [administrator, setAdministrator] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [viewPhoneNumber, setViewPhoneNumber] = useState(false);
  const { channels } = useSelector((state) => state.reducer);

  // Brand option list
  const [brandOptionList, setBrandOptionList] = useState([]);

  useEffect(() => {
    init();
  }, [item, openPopup]);

  useEffect(() => {
    // Render the list of brand option in 加入品牌 popup
    onSetBrandOptionList(
      channels,
      brands !== undefined ? brands : item?.channels ?? []
    );
  }, [item, brands]);

  const init = useCallback(() => {
    // Get user info from local storage
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    setIsAdmin(userInfo?.administrator ?? false);
    setLoginUserId(userInfo?.id ?? null);

    setUserId(item?.id ?? null);
    setName(item?.name ?? "");
    setUsername(item?.username ?? "");
    setPassword("");
    setConfirmPassword("");
    // setDept(item?.dept ?? "")
    setBrands(item?.channels ?? []);
    setAdministrator(item?.administrator ?? false);
    setDisabled(item?.disabled ?? false);
    setViewPhoneNumber(item?.viewPhoneNumber ?? false);
  }, [item]);

  function onSetBrandOptionList(defaultBrandList, selectedBrandList) {
    if (selectedBrandList?.length > 0 && defaultBrandList?.length > 0) {
      // List of selected brand ID
      let selectedBrandIdList = [];
      for (let selectedBrand of selectedBrandList) {
        selectedBrandIdList?.push(selectedBrand?.id);
      }

      // List of brand without selected brand
      let result = defaultBrandList?.filter(
        (defaultBrand) => !selectedBrandIdList?.includes(defaultBrand?.id)
      );
      setBrandOptionList(result);
    } else if (defaultBrandList?.length > 0) {
      // List of brand
      setBrandOptionList(defaultBrandList);
    }
  }

  function validateStaff() {
    var selectedBrandIdList = brands.map((brand) => brand.id);

    // Validate password
    if (password.trim() !== confirmPassword.trim()) {
      alert("密碼不正確");
    } else {
      // Save staff info
      onConfirm({
        id,
        name,
        username,
        password,
        administrator,
        disabled,
        viewPhoneNumber,
        channels: selectedBrandIdList,
      });
    }
  }

  function validateBrandList(brand) {
    if (brands.filter((e) => e.name === brand.name).length === 0) {
      // Add brand to brand list for display if no duplicated record
      setBrands([...brands, brand]);
    } else {
      alert("品牌已存在");
    }

    // Refresh popover component for brand list
    setAnchorEl(null);
  }

  const removeBrand = (index) => {
    var list = brands.slice();
    list.splice(index, 1);
    setBrands(list);
  };

  const renderBrandList = () => {
    if (brands.length > 0) {
      return (
        <div style={{ marginTop: "27px" }}>
          <div className={classes.row}>
            {/* <div className={classes.title} style={{ flex: 0.5 }}>優先次序</div> */}
            <div className={classes.title} style={{ flex: 2 }}>
              品牌
            </div>
          </div>
          {brands.map((item, index) => (
            <div key={`brand_${index}`} className={classes.row}>
              <div style={{ flex: 0.5 }}>
                <span className={classes.order}>{index + 1}</span>
              </div>
              <div className={classes.row} style={{ flex: 2, margin: 0 }}>
                <div className={classes.brandName}>{item.name}</div>
                {!isAdmin || !administrator ? (
                  <Button
                    variant="contained"
                    className={classes.deleteBrandButton}
                    startIcon={<img src={ic_trash_w} />}
                    onClick={() => removeBrand(index)}
                  >
                    刪除
                  </Button>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <Modal
      className={classes.modal}
      open={openPopup}
      onClose={() => {}}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        style={{
          width: 500,
          padding: 36,
          borderRadius: 18,
          backgroundColor: "#fff",
        }}
      >
        <div
          style={{
            fontSize: 18,
            fontWeight: 600,
            color: "#2c2c2c",
            marginBottom: "16px",
          }}
        >
          {isCreateNew ? "新增員工" : "修改員工"}
        </div>
        <div style={{ maxHeight: 450, overflowY: "scroll" }}>
          <div className={classes.row}>
            <div className={classes.title}>員工名稱</div>
            <TextField
              id="name"
              variant="outlined"
              size="small"
              className={classes.textInput}
              value={name}
              onChange={(event) => setName(event.target.value)}
              placeholder="輸入員工名稱"
            />
          </div>
          <div className={classes.row}>
            <div className={classes.title}>登入名稱</div>
            <TextField
              id="username"
              variant="outlined"
              size="small"
              className={classes.textInput}
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              placeholder="輸入登入名稱"
            />
          </div>
          {isAdmin || loginUserId === id ? (
            <div className={classes.row}>
              <div className={classes.title}>密碼</div>
              <TextField
                id="password"
                variant="outlined"
                size="small"
                type="password"
                className={classes.textInput}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                placeholder="輸入密碼"
              />
            </div>
          ) : (
            <div></div>
          )}
          {isAdmin || loginUserId === id ? (
            <div className={classes.row}>
              <div className={classes.title}>確認密碼</div>
              <TextField
                id="confirmPassword"
                variant="outlined"
                size="small"
                type="password"
                className={classes.textInput}
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
                placeholder="輸入確認密碼"
              />
            </div>
          ) : (
            <div></div>
          )}
          {/* <div className={classes.row}>
            <div className={classes.title}>員工編號</div>
            <TextField
              id="name"
              variant="outlined"
              size="small"
              className={classes.textInput}
              value={name}
              onChange={(event) => setUsername(event.target.value)}
              placeholder="輸入員工編號" />
          </div>
          <div className={classes.row}>
            <div className={classes.title}>職位</div>
            <TextField
              id="dept"
              variant="outlined"
              size="small"
              className={classes.textInput}
              value={dept}
              onChange={(event) => setDept(event.target.value)}
              placeholder="輸入職位" />
          </div> */}
          <div className={classes.row}>
            <div className={classes.title}>管理員</div>
            <div style={{ flex: 2 }}>
              <input
                id="administrator"
                type="checkbox"
                className="administrator"
                checked={administrator}
                onChange={(event) => setAdministrator(event.target.checked)}
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.title}>暫停用戶</div>
            <div style={{ flex: 2 }}>
              <input
                id="disabled"
                type="checkbox"
                className="disabled"
                checked={disabled}
                onChange={(event) => setDisabled(event.target.checked)}
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.title}>電話顯示</div>
            <div style={{ flex: 2 }}>
              <input
                id="viewPhoneNumber"
                type="checkbox"
                className="viewPhoneNumber"
                checked={viewPhoneNumber}
                onChange={(event) => setViewPhoneNumber(event.target.checked)}
              />
            </div>
          </div>
          {!isAdmin || !administrator ? (
            <div className={classes.row}>
              <div className={classes.title}>負責品牌</div>
              <div style={{ flex: 2 }}>
                <Button
                  variant="contained"
                  className={classes.brandButton}
                  startIcon={<img src={ic_add} />}
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                  加入品牌
                </Button>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {renderBrandList()}
        </div>
        <div className={classes.buttonContainer}>
          {/* {!isCreateNew ? <Button variant="contained" className={`${classes.brandButton} ${classes.deleteStaffButton}`} startIcon={<img src={ic_trash_r} />} onClick={onDelete}>刪除員工</Button> : <div></div>} */}
          <div>
            <Button
              variant="contained"
              className={classes.modalButton}
              onClick={onCancel}
            >
              取消
            </Button>
            <Button
              variant="contained"
              className={classes.modalButton}
              style={{
                backgroundColor: "#0059c1",
                color: "#fff",
                marginLeft: "16px",
              }}
              onClick={() => validateStaff()}
              disabled={!name.trim().length || !username.trim().length}
            >
              確定
            </Button>
          </div>
        </div>
        <Popover
          id="popover-list"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div style={{ padding: "14px", overflowY: "hidden" }}>
            <SearchBar
              onChange={(value) => setSearch(value)}
              customStyle={{ margin: "0 0 10px" }}
            />
            <ul
              className={classes.ul}
              style={{ maxHeight: "200px", overflowY: "scroll" }}
            >
              {brandOptionList?.map((brand, index) => {
                var isSelected = false;
                return (
                  <li
                    key={`brand_${index}`}
                    className={classes.brandItem}
                    onClick={() => validateBrandList(brand)}
                  >
                    <img
                      src={isSelected ? btn_remove : btn_add}
                      style={{ marginRight: "16px" }}
                    />
                    <img className={classes.brandIcon} src={brand?.logo} />
                    <div className={classes.brandName}>{brand?.name}</div>
                  </li>
                );
              })}
            </ul>
          </div>
        </Popover>
      </div>
    </Modal>
  );
}
