import React, {createContext, useContext} from 'react';
import PropTypes from 'prop-types';

export const MenuContext = createContext({});
export default class MenuProvider extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      autoReply: '1',
      defaultPhoneNumberMaskLength: '',
      highlightedContacts: [],
      openDrawer: true,
    };
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  getChildContext() {
    return { autoReply: this.state.autoReply, defaultPhoneNumberMaskLength: this.state.defaultPhoneNumberMaskLength, highlightedContacts: this.state.highlightedContacts, openDrawer: this.state.openDrawer, setState: (state) => this.setState(prev => ({ ...prev, ...state })) };
  }

  render() {
    // return React.Children.toArray(this.props.children);
    return <MenuContext.Provider value={this.getChildContext()}>{this.props.children}</MenuContext.Provider>;
  }
}

MenuProvider.childContextTypes = {
  autoReply: PropTypes.string,
  defaultPhoneNumberMaskLength: PropTypes.string,
  highlightedContacts: PropTypes.arrayOf(PropTypes.string),
  openDrawer: PropTypes.bool,
  setState: PropTypes.func,
};

export const useMenu = () => useContext(MenuContext);