import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import ic_addstaff from "../../assets/img/setting/ic_addstaff.svg";
import InfiniteScroll from "react-infinite-scroller";
import CreateStaffModal from "./CreateStaffModal";
import DeleteStaffModal from "../../components/permission/DeleteStaffModal";

class StaffList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.isFetching = false;
    this.state = {
      search: "",
      hasMoreItems: true,
      openPopup: false,
      openDeletePopup: false,
      selectedStaff: null,
      staffList: [],
    };
  }

  componentDidMount() {
    // this.getAllUser();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  getAllUser() {
    const { socket } = this.context;
    const { staffList } = this.state;

    socket.emit("getAllUsers", null, (err, res) => {
      if (err) {
        alert(err);
      } else {
        // console.log('getAllUsers')
        // console.log(res)
        this.setState({ staffList: res });
      }
    });
  }

  loadMore() {
    const { socket } = this.context;
    const { staffList, hasMoreItems } = this.state;
    // console.log("isFetching", this.isFetching, "hasMoreItems", hasMoreItems)
    if (!this.isFetching && hasMoreItems) {
      this.isFetching = true;
      var data =
        staffList.length > 0
          ? { beforeId: staffList[staffList.length - 1].id }
          : null;
      socket.emit("getAllUsers", data, (err, res) => {
        this.isFetching = false;
        if (err) {
          alert(err);
        } else {
          if (res.length == 0) {
            // console.log('No More')
            this.setState({ hasMoreItems: false });
          } else {
            // console.log(res)
            // this.setState({ staffList: [...staffList, ...res] })
            this.setState({ staffList: staffList.concat(res) });
          }
        }
      });
    }
  }

  onUpdateStaff(data) {
    const { socket } = this.context;

    socket.emit("saveUser", data, (err, res) => {
      if (err) {
        alert(err);
      } else {
        if (res.length == 0) {
          // console.log("saveUser: No result");
          this.setState({ openPopup: false, selectedStaff: null });
        } else {
          // console.log(res);
          // Fetch the latest staff list and rebuild the list
          this.setState({ openPopup: false, selectedStaff: res }, () => {
            this.setState({ staffList: [], hasMoreItems: true });
            this.getAllUser();
          });
        }
      }
    });
  }

  onDeleteStaff() {
    this.setState({ openDeletePopup: false, openPopup: false });
  }

  renderList() {
    const { staffList } = this.state;
    var renderList = staffList
      ?.filter((x) =>
        x.name.toUpperCase().includes(this.state.search?.trim()?.toUpperCase())
      )
      ?.map((item, index) => (
        <div
          key={`list_${index}`}
          className="list"
          onClick={() =>
            this.setState({ selectedStaff: item, openPopup: true })
          }
        >
          <div className="list-item name ">{item.name}</div>
          <div
            className="brand-list"
            style={{ flex: 2, flexWrap: "wrap", rowGap: "5px" }}
          >
            {item?.channels?.map((brand) => (
              <img key={brand.id} className="brand-icon" src={brand.logo}></img>
            ))}
          </div>
        </div>
      ));
    return renderList;
  }

  render() {
    const { staffList } = this.state;
    return (
      <div id="staff-container">
        <div className="top-container">
          <div
            className="add-button"
            onClick={() =>
              this.setState({ selectedStaff: null, openPopup: true })
            }
          >
            <img src={ic_addstaff} />
            <span>新增員工</span>
          </div>
          <SearchBar onChange={(value) => this.setState({ search: value })} />
        </div>
        <p className="staff-info">員工：{staffList.length} 人</p>
        <div className="list">
          <div className="list-item table-title">員工名稱</div>
          <div className="list-item table-title" style={{ flex: 2 }}>
            負責品牌
          </div>
        </div>
        <div
          style={{ width: "100%", overflow: "auto" }}
          ref={(ref) => (this.scrollParentRef = ref)}
        >
          <InfiniteScroll
            pageStart={0}
            loadMore={this.loadMore.bind(this)}
            hasMore={this.state.hasMoreItems}
            loader={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="loader" key={0}></div>
              </div>
            }
            initialLoad={true}
            useWindow={false}
            getScrollParent={() => this.scrollParentRef}
            threshold={50}
          >
            {this.renderList()}
          </InfiniteScroll>
        </div>
        <CreateStaffModal
          openPopup={this.state.openPopup}
          item={this.state.selectedStaff}
          onConfirm={(data) => this.onUpdateStaff(data)}
          onCancel={() =>
            this.setState({ openPopup: false, selectedStaff: null })
          }
          onDelete={() => this.setState({ openDeletePopup: true })}
        />
        <DeleteStaffModal
          item={this.state.selectedStaff}
          openPopup={this.state.openDeletePopup}
          onConfirm={() => this.onDeleteStaff()}
          onCancel={() => this.setState({ openDeletePopup: false })}
        />
      </div>
    );
  }
}

StaffList.contextTypes = {
  socket: PropTypes.object.isRequired,
};

export default withRouter(StaffList);
