import React, { useState } from 'react';
import { Dialog, AppBar, Toolbar, IconButton, Typography, Button, TextField, MenuItem } from '@mui/material';
import btn_close_white from '.././assets/img/inbox/btn_close_white.svg';

export default function FollowUserEdit(props) {
  const { onClose, open, staffList, context, currentChannel } = props;
  const { socket } = context;
  const [followUserId, setFollowUserId] = useState(``);

  const renderSelect = () => {
    const staffItems = staffList?.map((item, index) =>
      <MenuItem key={`staff_${item.name}`} value={item.id} style={{fontSize:16}}>
        {item.name}
      </MenuItem>
    )
    return (
      <TextField
      id="contact_add_channel"
      className='contact-add-channel-select'
      select
      value={followUserId}
      onChange={event => handleFollowUserChange(event)}
      style={{fontSize:16}}
      label="請選擇"
    >
      {staffItems}
    </TextField>
    );
  }

  const handleFollowUserChange = (e) => {
    setFollowUserId(e.target.value);
  }

  const sendAddContactFollowUser = () => {
    var data = {
      contactId: currentChannel?.id ?? null,
      userId: followUserId ?? null,
    }
    socket.emit('addContactFollowUser', data, (err, res) => {
      if (err) {
        alert(err);
      } else {
        onClose();
        setFollowUserId(``);
      }
    });
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        setFollowUserId(``);
      }}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              onClose();
              setFollowUserId(``);
            }}
            aria-label="close"
          >
            <img src={btn_close_white} />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} align="center" variant="h10" component="div">
            更改負責人
          </Typography>
          <div style={{width:31}} />
        </Toolbar>
      </AppBar>
      <div className='contact-add-popup-container'>
        <div className='input-container'>
          <label className='input-label' htmlFor='contact_add_channel'>負責人</label>
          {renderSelect()}
          <div className='button-container'>
            <Button variant="contained" className='send' style={{minWidth:150,borderRadius:10}} disabled={(followUserId==='')} onClick={() => { sendAddContactFollowUser(); }}>更改</Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}