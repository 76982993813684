import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import SearchBar from '../../components/SearchBar'
import CreateBrandModal from './CreateBrandModal';
import { connect } from 'react-redux'

class BrandList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      openPopup: false,
      selectedBrand: null,
      search: '',
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    const { channels } = this.props;
    return (
      <div id="brand-container">
        <div className="top-container">
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <div className="title">品牌列表</div>
          </div>
          <SearchBar onChange={(value) => this.setState({ search: value })} customStyle={{ margin: '20px 0 0' }} />
        </div>
        <ul style={{ overflow: 'auto' }}>
          {channels?.filter(x => x.name.toUpperCase().includes(this.state.search?.trim()?.toUpperCase()))?.map((item, index) => {
            var isSelected = this.state.selectedBrand == item;
            return (
              <li key={`brand_${index}`} onClick={() => this.setState({ selectedBrand: item, openPopup: true })} style={{ backgroundColor: isSelected ? '#0059c1' : '#fff' }}>
                <img className="brand-icon" src={item?.logo} />
                <div className="brand-name" style={{ color: isSelected ? '#fff' : '#2c2c2c' }}>{item?.name}</div>
              </li>
            )
          })}
        </ul>
        <CreateBrandModal
          openPopup={this.state.openPopup}
          item={this.state.selectedBrand}
          onConfirm={(data) => this.setState({ openPopup: false, selectedBrand: null })} />
      </div>
    )
  }
}

BrandList.contextTypes = {
  socket: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return { channels: state.reducer.channels }
}

export default connect(mapStateToProps)(withRouter(BrandList));