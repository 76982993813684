import React, {useCallback, useEffect, useState} from "react";
import { withRouter } from "react-router-dom";
import { CircularProgress, Dialog, DialogContent, IconButton } from '@mui/material';
import { grey } from "@mui/material/colors";
import { styled } from '@mui/material/styles';
import TopBar from "../../components/TopBar";
import logo_whatsapp from "../../assets/img/channel_status/logo_whatsapp.svg";
import {useMenu} from "../../components/menu/MenuProvider";
import {useSocket} from "../../Socket";
import QrCodeIcon from '@mui/icons-material/QrCode';


const QRCodeDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
}));

const QRCodeButton = styled(IconButton)(({ theme }) => ({
  '&.Mui-disabled': {
    color: grey[600],
  },
}));

const ChannelStatus = props => {
  const {openDrawer, setState} = useMenu();
  const {socket} = useSocket();

  const [channelStatus, setChannelStatus] = useState([]);
  const [selectedChannelId, setSelectedChannelId] = useState(null);
  const [qrCode, setQrCode] = useState(null);

  useEffect(() => {
    if (socket) {
      socket.emit("getChannelStatus", null, (err, res) => {
        if (err) {
          alert(err);
        } else {
          // console.log(res);
          setChannelStatus(res);
        }
      });
    }
  }, [socket]);

  useEffect(() => {
    let channel;
    if (socket && selectedChannelId) {
      channel = socket.subscribe(`chatApiChannelStatus/${selectedChannelId}`);
      channel.on('subscribe', (err, res) => {
        if (res) {
          channel.watch((data) => {
            const {channelStatus: {active, raw}} = data;
            if (active && raw.status === "authenticated") {
              setChannelStatus(prev => prev?.map((item, index) => {
                const {id} = item;
                if (id === selectedChannelId) {
                  return { ...item, online: true};
                } else {
                  return item;
                }
              }));
              setSelectedChannelId(null);
              setQrCode(null);
            }
          });
        }
      })
    }

    return () => {
      if (socket) {
        socket.unsubscribe('chatApiChannelStatus/' + selectedChannelId);
      }
    }
  }, [selectedChannelId]);

  const onClickQRCode = useCallback((id) => {
    if (socket) {
      setSelectedChannelId(id);
      socket.emit("getChatApiChannelStatus", {id}, (err, res) => {
        if (err) {
          alert(err);
        } else {
          if (res.qrCode) {
            //Display QRCode for Scanning
            setQrCode(res.qrCode);
          }
        }
      });
    }
  }, [socket]);

  const onCloseQr = useCallback(() => {
    setQrCode(null);
    setSelectedChannelId(null);
  }, []);

  const renderStatus = useCallback((item) => {
    const {id, serviceProvider, online} = item;
    
    if (online === true) {
      return (
        <div className="status-container connected-status">
          <div>已連結</div>
        </div>
      );
    } else {
      return (
        <div className="row">
          {
            serviceProvider === 'Chat-API' && (
              <div className="status-container">
                {
                  selectedChannelId === id ? (
                    <CircularProgress size={32} />
                  ) : (
                    <QRCodeButton 
                      color="primary"
                      component="span"
                      disabled={selectedChannelId !== null}
                      onClick={() => onClickQRCode(id)}
                    >
                      <QrCodeIcon />
                    </QRCodeButton>
                  )
                }
              </div>
            )
          }
          <div className="status-container fail-status">
            <div>連結失效</div>
          </div>
        </div>
      );
    }
  }, [onClickQRCode, selectedChannelId]);

  return (
    <>
      <div id="channel-setting" className={!openDrawer ? "openDrawer" : ""}>
        <TopBar title="頻道狀態" {...props} {...({openDrawer, setState, socket})}/>
        <div className="list-container">
          <div className="title">目前頻道</div>
          <div className="channel-list">
            {channelStatus?.map((item, index) => {
              return (
                <div key={index} className="list-item">
                  <img
                    className="brand-icon"
                    style={{
                      width: "50px",
                      height: "50px",
                      border: "solid 1px #dedede",
                      borderRadius: "50%",
                      marginRight: "16px",
                    }}
                    src={item?.logo}
                    placeholder={logo_whatsapp}
                    alt=''
                  />
                  <div className="info">
                    <div className="username">
                      {item?.name} | {item?.channelPhoneNumber}
                    </div>
                    <div className="row">
                      <div className="channel-name">{item.serviceProvider}</div>
                      {renderStatus(item)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <QRCodeDialog
        open={!!qrCode}
        onClose={onCloseQr}
      >
        <DialogContent>
          <img src={qrCode} alt='' style={{width: "100%", height: "auto"}}/>
        </DialogContent>
      </QRCodeDialog>
    </>
  );
};

export default withRouter(ChannelStatus);
