import React, { } from 'react';
import { Modal, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ic_brand from '../../assets/img/setting/ic_brand.svg';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    textAlign: 'center',
    marginTop: '32px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '20px 0',
  },
  title: {
    fontSize: '14px',
    color: '#6d6d6d',
    flex: 1,
  },
  modalButton: {
    padding: '8px 27px',
    borderRadius: '10px',
    backgroundColor: '#dedede',
    fontSize: '14px',
    fontWeight: '500',
    color: '#2c2c2c',
    boxShadow: 'none',
    '& .Mui-disabled': {
      opacity: 0.45,
    }
  },
  brandButton: {
    borderRadius: '6px',
    border: 'solid 1px #dedede',
    backgroundColor: '#fff',
    fontSize: '14px',
    fontWeight: '500',
    color: '#2c2c2c',
    boxShadow: 'none',
  },
  updateLogoButton: {
    margin: '0 16px',
  },
  removeLogoButton: {
    fontSize: '14px',
    color: '#0059c1',
    textDecoration: 'underline',
  },
  brandInfo: {
    flex: 2,
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#2c2c2c',
  },
  logo: {
    width: '85px',
    height: '85px',
    borderRadius: '50%',
    border: 'solid 1px #dedede',
  },
})

export default function CreateBrandModal(props) {
  const { item, onConfirm, openPopup } = props;
  const classes = useStyles();

  const renderUploadLogo = () => {
    return (
      <div className={classes.row} style={{ justifyContent: 'flex-start' }}>
        <div className={classes.logo} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><img src={ic_brand} alt="ic_brand" /></div>
        <Button variant="contained" className={`${classes.brandButton} ${classes.updateLogoButton}`} onClick={() => { }}>上傳Logo</Button>
      </div>
    )
  }

  const renderUpdateLogo = () => {
    return (
      <div className={classes.row} style={{ justifyContent: 'flex-start' }}>
        <img className={classes.logo} src={item.logo} alt={item.name} />
        {/* <Button variant="contained" className={`${classes.brandButton} ${classes.updateLogoButton}`} onClick={() => { }}>更換Logo</Button>
        <Button className={classes.removeLogoButton} onClick={() => { }}>移除Logo</Button> */}
      </div>
    )
  }

  return (
    <Modal
      className={classes.modal}
      open={openPopup}
      onClose={() => { }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={{ width: 500, padding: 36, borderRadius: 18, backgroundColor: '#fff' }}>
        <div style={{ fontSize: 18, fontWeight: 600, color: '#2c2c2c', marginBottom: '16px' }}>品牌詳情</div>
        <div style={{ maxHeight: 450, overflowY: 'scroll' }}>
          {item?.logo ? renderUpdateLogo() : renderUploadLogo()}
          <div className={classes.row}>
            <div className={classes.title}>品牌名稱</div>
            <div className={classes.brandInfo}>{item?.name}</div>
          </div>
          <div className={classes.row}>
            <div className={classes.title}>品牌電話</div>
            <div className={classes.brandInfo}>{item?.channelPhoneNumber}</div>
          </div>
          <div className={classes.row}>
            <div className={classes.title}>負責員工</div>
            <div className={classes.brandInfo}>{item?.users?.map(x => x.name)?.join(', ')}</div>
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button variant="contained" className={classes.modalButton} style={{ backgroundColor: '#0059c1', color: '#fff', marginLeft: '16px' }} onClick={onConfirm}>確定</Button>
        </div>
      </div>
    </Modal>
  )
}