import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { Auth } from '../../Auth';
import Cookies from 'universal-cookie';

import login_icon from '../../assets/img/login/icon-login-black.png';
import password from '../../assets/img/login/icon-password.png';
import { connect } from 'react-redux';
import { onUpdateAuth } from '../../redux/action';

const cookies = new Cookies();
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      password: '',
      autologin: false,
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleAutoLoginChange = this.handleAutoLoginChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  componentDidMount() {
    const { socket } = this.context;

    if (!socket) {
      console.error('Socket IO connection has not been established.');
      return;
    } else {
      console.log('Socket IO connection has been established.');
    }

    // console.log('Remember me: '+Auth.rememberMe)
    // console.log('isLoggedIn: '+Auth.isLoggedIn())
    if (Auth.rememberMe === true) {
      Auth.autoLogin(socket, cookies, () => { this.props.history.replace('/inbox') });
    }
  }

  // componentWillMount(){
  //   if(Auth.isLoggedIn){
  //     this.props.history.push('/index');
  //   }
  // }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleAutoLoginChange(event) {
    this.setState(prevState => ({ autologin: !prevState.autologin }));
  }

  handleLogin(event) {
    event.preventDefault();

    const { name, password } = this.state;

    if ((name && name.trim().length) && (password && password.trim().length)) {
      const { socket } = this.context;
      Auth.login(name, password, this.state.autologin, socket, cookies, () => this.props.history.replace('/inbox'));
    } else {
      alert('請輸入帳號和密碼')
    }
    return false;
  }

  render() {
    return (
      <div id="login">
        <div className="login-container">
          <form id="login-form" onSubmit={this.handleLogin}>
            <div id="user-name" className="user-input">
              <div className="icon"><img src={login_icon} alt="login" /></div>
              <input type="text" value={this.state.name} placeholder="輸入帳號" onChange={this.handleNameChange} />
            </div>
            <div id="user-password" className="user-input">
              <div className="icon"><img src={password} alt="password-icon" /></div>
              <input type="password" value={this.state.password} placeholder="輸入密碼" onChange={this.handlePasswordChange} />
            </div>
            <label id="auto-login"><input id="checkbox" type="checkbox" className="auto-login" checked={this.state.autologin} onChange={this.handleAutoLoginChange} />自動登入</label>
            <input type="submit" className="login-button" value="登入" />
            <input className="forgot-password" type="button" value="忘記密碼？" />
          </form>
        </div>
      </div>
    );
  }
}

Login.contextTypes = {
  socket: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return { isAuthenticated: state.reducer.isAuthenticated }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateAuth: (bool) => {
      dispatch(onUpdateAuth(bool));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));