import React, { useState } from 'react';
import { Dialog, AppBar, Toolbar, IconButton, Typography, Button, TextField, MenuItem } from '@mui/material';
import btn_close_white from '.././assets/img/inbox/btn_close_white.svg';

export default function ContactAdd(props) {
  const { onClose, open, channels, context, updateBackdrop, isExpired, searchChatList } = props;
  const { socket } = context;
  const [contactChannel, setContactChannel] = useState();
  const [contactTel, setContactTel] = useState();

  const renderSelect = () => {
    const channelItems = channels?.filter((channel) => channel?.active === true).map((item, index) =>
      <MenuItem key={`channel_${item.name}`} value={item.channelPhoneNumber} style={{fontSize:16}}>
        {item.name}
      </MenuItem>
    )
    return (
      <TextField
      id="contact_add_channel"
      className='contact-add-channel-select'
      select
      value={contactChannel}
      onChange={event => handleChannelChange(event)}
      style={{fontSize:16}}
      label={"請選擇"}
    >
      {/* <MenuItem key={`channel_null`} value='' >&nbsp;</MenuItem> */}
      {channelItems}
    </TextField>
    );
  }

  const handleChannelChange = (e) => {
    setContactChannel(e.target.value);
  }

  const handleTelChange = (e) => {
    setContactTel(e.target.value);
  }

  const btnDisable = () => {
    return contactTel?.length === 0 || contactTel === undefined;
  }

  const sendContactAdd = () => {
    if (contactTel?.length === 0) return;
    var data = {
      channelPhoneNumber: contactChannel ?? null,
      whatsappId: contactTel,
    }
    updateBackdrop(true);
    socket.emit('addContact', data, (err, res) => {
      if (err) {
        alert(err);
        updateBackdrop(false);
        onClose();
      } else {
        updateBackdrop(false);
        onClose();
        searchChatList({keywords: contactTel, isExpired: isExpired});
      }
    });
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <img src={btn_close_white} />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} align="center" variant="h10" component="div">
            新增對話資訊
          </Typography>
          <div style={{width:31}} />
        </Toolbar>
      </AppBar>
      <div className='contact-add-popup-container'>
        <div className='input-container'>
          <label className='input-label' htmlFor='contact_add_channel'>頻道</label>
          {renderSelect()}
          <label className='input-label' htmlFor='contact_add_tel' style={{marginTop:10}}><span style={{color:'red'}}>*</span>&nbsp;聯絡電話</label>
          <TextField
            id="contact_add_tel"
            type="text"
            onChange={event => handleTelChange(event)}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
          <div className='button-container'>
            <Button variant="contained" className='send' style={{minWidth:150,borderRadius:10}} disabled={btnDisable()} onClick={() => { sendContactAdd(); }}>確認</Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}