import React from 'react';
import moment from 'moment';
import ic_deliver from '../assets/img/ic_chatstatus/ic_deliver.svg';
import ic_read from '../assets/img/ic_chatstatus/ic_read.svg';
import ic_sent_alert from '../assets/img/ic_chatstatus/ic_sent_alert.svg';
import ic_sent from '../assets/img/ic_chatstatus/ic_sent.svg';

export default function MessageInfo(props) {
  const { name, timestamp, self, brand = "" } = props;

  const renderStatus = () => {
    const { status } = props;
    if (status?.toLowerCase() == 'sent') {
      return <img src={ic_sent} />
    } else if (status?.toLowerCase() == "delivered") {
      return <img src={ic_deliver} />
    } else if (status?.toLowerCase() == "read") {
      return <img src={ic_read} />
    }
  }

  const renderUser = () => {
    return (
      <div id="message-info" style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
        {props.status == "deleted" && <div className="text deleted">已刪除</div>}
        <span className="text">傳送至 <span className="brand">{brand}</span> | {moment(timestamp).format('YYYY-MM-DD HH:mm')}</span>
      </div>
    )
  }

  const renderSelf = () => {
    return (
      <div id="message-info" className="self">
        <span className="text">{name} | <span className="brand">{brand}</span> | {moment(timestamp).format('YYYY-MM-DD HH:mm')} </span>
        {renderStatus()}
      </div>
    )
  }

  if (self === true) {
    return renderSelf()
  } else {
    return renderUser()
  }
}