import React from 'react';
import Linkify from 'react-linkify';

class TextMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { text, self, read, status, errorMsg = false } = this.props;
    return (
      <div id="message" className={`${'text-message'} ${self ? 'self' : ''}`}>
        <div className='row-container'>
          <Linkify properties={{ target: '_blank', style: { color: '#528fe6' } }}><span className={errorMsg ? 'errorMsg' : ''}>{text}</span></Linkify>
          {status == 'failed' && <p className="failed">!</p>}
        </div>
        {/* {read && <p className="read">已讀</p>} */}
      </div>
    )
  }
}

export default TextMessage;