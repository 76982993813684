import React, { useState, useRef } from 'react';
import { makeStyles } from '@mui/styles';
// import { Button, Modal, TextField } from '@mui/material';
// import btn_edit from '../assets/img/setting/btn_edit.svg';

const useStyles = makeStyles({
  container: {
    padding: '25px 0',
    backgroundColor: '#fff',
    marginLeft: '15px',
    width: '250px',
    overflowY: 'scroll',
  },
  userInfoContainer: {
    padding: '0 33px',
  },
  remarkContainer: {
    marginTop: '35px',
    borderTop: 'solid 1px #dedede',
    borderBottom: 'solid 1px #dedede',
    padding: '23px 16px 23px 33px',
  },
  name: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#2c2c2c',
  },
  infoTitle: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#b1b1b1',
  },
  infoText: {
    fontSize: '14px',
    color: '#2c2c2c',
  },
  button: {
    padding: '5px 13px',
    borderRadius: '9px',
    backgroundColor: '#dedede',
    fontSize: '14px',
    color: '#2c2c2c',
    boxShadow: 'none',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalButton: {
    padding: '8px 27px',
    borderRadius: '10px',
    backgroundColor: '#dedede',
    fontSize: '14px',
    fontWeight: '500',
    color: '#2c2c2c'
  },
  textInput: {
    width: '100%',
    marginTop: '15px',
    backgroundColor: '#f6f6f6',

    '& .MuiInputBase-root' : {
      fontSize: '14px',
    }
  }
})

export default function ClientInfo(props) {
  const classes = useStyles();
  const { item, currentContact, data } = props;
  const [remark, setRemark] = useState("")
  const [openRemarkPopup, setOpenRemarkPopup] = useState(false)
  const remarkTextInputRef = useRef(null)

  if ( data == null || data.length == 0 ) { 
    return <div></div>; 
  } else {
    return (
      <div className={classes.container}>
        <div className={classes.userInfoContainer}>
          <div className={classes.name}>{item.name}</div>
          <div style={{ marginTop: 27 }}><span className={classes.infoTitle}>會員編號：</span><span className={classes.infoText}>{ data.username }</span></div>
          {/* <div><span className={classes.infoTitle}>電話號碼：</span><span className={classes.infoText}>{ item?.name }</span></div> */}
          <div><span className={classes.infoTitle}>電話號碼：</span><span className={classes.infoText}>{ data?.tel }</span></div>
          <div><span className={classes.infoTitle}>顧問：</span><span className={classes.infoText}>{ data.consultant?.name }</span></div>
          <div><span className={classes.infoTitle}>治療師：</span><span className={classes.infoText}>{ data.therapist?.name }</span></div>
          <div><span className={classes.infoTitle}>消費組別：</span><span className={classes.infoText}>{ data.grade }</span></div>
          <div><span className={classes.infoTitle}>類別：</span><span className={classes.infoText}>{ data.status }</span></div>
          <div><span className={classes.infoTitle}>XY類別：</span><span className={classes.infoText}>{ data.xy }</span></div>
          <div><span className={classes.infoTitle}>最後療程分店：</span><span className={classes.infoText}>{ data.lastShop?.name }</span></div>
          <div><span className={classes.infoTitle}>最後療程：</span><span className={classes.infoText}>{ data.lastTreatments?.length ? data.lastTreatments.map(v => v.name).join(', ') : null }</span></div>
          <div><span className={classes.infoTitle}>下次預約日期：</span><span className={classes.infoText}>{ data.nextBooking?.date }</span></div>
        </div>
      </div>
        // <div className={classes.remarkContainer}>
        //   <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        //     <div className={classes.name}>備註</div>
        //     <Button
        //       variant="contained"
        //       className={classes.button}
        //       startIcon={<img src={btn_edit} />}
        //       onClick={() => setOpenRemarkPopup(true)}
        //     >
        //       修改
        //     </Button>
        //   </div>
        //   <div className={classes.infoText}>{remark}</div>
        // </div>
        // <Modal
        //   className={classes.modal}
        //   open={openRemarkPopup}
        //   onClose={() => { }}
        //   aria-labelledby="simple-modal-title"
        //   aria-describedby="simple-modal-description"
        // >
        //   <div style={{ width: 449, padding: 36, borderRadius: 18, backgroundColor: '#fff' }}>
        //     <div style={{ fontSize: 18, fontWeight: 600, color: '#2c2c2c' }}>備註</div>
        //     <TextField
        //       inputRef={remarkTextInputRef}
        //       id="outlined-basic"
        //       variant="outlined"
        //       className={classes.textInput}
        //       multiline
        //       rows={4}
        //       defaultValue={remark}
        //       placeholder="輸入職位" />
        //     <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '32px' }}>
        //       <Button variant="contained" className={classes.modalButton} onClick={() => setOpenRemarkPopup(false)}>取消</Button>
        //       <Button variant="contained" className={classes.modalButton} style={{ backgroundColor: '#0059c1', color: '#fff', marginLeft: '16px' }}
        //         onClick={() => {
        //           setOpenRemarkPopup(false);
        //           setRemark(remarkTextInputRef.current.value);
        //         }}
        //       >
        //         確定
        //       </Button>
        //     </div>
        //   </div>
        // </Modal>
    )
  }
}