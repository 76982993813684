import React from "react";
import Notification from "react-web-notification";
import moment from "moment";

import loading from "./assets/img/chat/icon-loading.png";
import soundMp3 from "./assets/sound/to-the-point.mp3";
import soundOgg from "./assets/sound/to-the-point.ogg";

//allow react dev tools work
window.React = React;

export const AppNotification = (ComposedComponent) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        ignore: true,
        title: "",
        hidden: false,
        registration: null,
      };
    }

    async getServiceWorker() {
      if ('serviceWorker' in navigator && 'PushManager' in window) {
        try {
          navigator.serviceWorker.ready.then((registration) => {
            this.setState({ registration });
          })
          .catch(error => {
              console.error('navigator.serviceWorker error: ', error);
          });
          
        } catch (error) {
            console.error('Error sending message to service worker:', error);
        }
      }
    }

    componentDidMount() {
      this.getServiceWorker();
    }

    handlePermissionGranted() {
      // console.log('Permission Granted');
      this.setState({
        ignore: false,
      });
    }
    handlePermissionDenied() {
      // console.log('Permission Denied');
      this.setState({
        ignore: true,
      });
    }
    handleNotSupported() {
      // console.log('Web Notification not Supported');
      this.setState({
        ignore: true,
      });
    }

    handleNotificationOnClick(e, tag) {
      // console.log(e, 'Notification clicked tag:' + tag);
    }

    handleNotificationOnError(e, tag) {
      // console.log(e, 'Notification error tag:' + tag);
    }

    handleNotificationOnClose(e, tag) {
      // console.log(e, 'Notification closed tag:' + tag);
    }

    handleNotificationOnShow(e, tag) {
      this.playSound();
      // console.log(e, 'Notification shown tag:' + tag);
    }

    playSound(filename) {
      document.getElementById("wsSound").play();
    }

    pushNotification(data, chat) {
      if (this.state.ignore) {
        return;
      }

      let hasChannelAccess = this.checkChannelAccess(data);
      if (!hasChannelAccess) {
        // Not to send notification if the account does not have access to the channel
        return;
      }

      // if(TabVisibility.isHidden() == false) {return;}
      const name = data?.name || chat?.name;

      const title = "你收到一個新訊息";
      const body = name + " " + moment(data.timestamp).format("HH:mm a");
      const tag = data.id;
      const icon = loading;

      const options = {
        tag: tag,
        body: body,
        icon: icon,
        lang: "en",
        dir: "ltr",
        sound: soundMp3,
      };
      this.setState({
        title: title,
        options: options,
      });
      if (this.state.registration != null) {
        this.playSound();
      }
    }

    checkChannelAccess(notificationData) {
      // Load channel list from user info
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const administrator = userInfo?.administrator ?? "";
      const channelList = userInfo?.channels ?? [];

      if (!administrator) {
        let accessChannelList = channelList?.filter(
          (channel) =>
            channel?.channelPhoneNumber === notificationData?.channelPhoneNumber
        );
        if (accessChannelList?.length === 0) {
          // No notification receive
          return false;
        }
        // Receive notification
        return true;
      }
      // Admin will receive all notification
      return true;
    }

    render() {
      return (
        <React.Fragment>
          <ComposedComponent
            pushNotification={(data, chat) => {
              this.pushNotification(data, chat);
            }}
            {...this.props}
          />
          <Notification
            ignore={this.state.ignore && this.state.title !== ""}
            notSupported={this.handleNotSupported.bind(this)}
            onPermissionGranted={this.handlePermissionGranted.bind(this)}
            onPermissionDenied={this.handlePermissionDenied.bind(this)}
            onShow={this.handleNotificationOnShow.bind(this)}
            onClick={this.handleNotificationOnClick.bind(this)}
            onClose={this.handleNotificationOnClose.bind(this)}
            onError={this.handleNotificationOnError.bind(this)}
            timeout={5000}
            title={this.state.title}
            options={this.state.options}
            swRegistration={this.state.registration}
          />
          <audio id="wsSound" preload="auto">
            <source src={soundMp3} type="audio/mpeg" />
            <source src={soundOgg} type="audio/ogg" />
            <embed
              hidden={true}
              autostart="false"
              loop={false}
              src={soundMp3}
            />
          </audio>
        </React.Fragment>
      );
    }
  };
};
